import { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import { ModalHeader } from "../../../Modals/ModalHeader";
import clsx from "clsx";
import { useAppSelector } from "../../../../setup/hooks/redux";
import { closeModal, setRefresh } from "../reducers/CategorySlice";
import { useDispatch } from "react-redux";
import { Api } from "../../../Services/api";
import Swal from "sweetalert2";
import { AddPhoto } from "../../components/AddPhoto";

type Props = {
  avatar: any;
};

const CategorySchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "En az 3 karakter olmalı!")
    .required("Zorunlu alan"),
  //   avatar_cagetory_id: Yup.number().required('Zorunlu alan'),
});

const AddAvatar: FC<Props> = ({ avatar }) => {
  // states
  const [showCover, setShowCover] = useState<any>([]);

  const [categories, setCategories] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState(
    avatar?.category?.id
  );
  const { modalActive, updateFile } = useAppSelector((state) => state.avatar);
  const dispatch = useDispatch();
  const [categoryForEdit] = useState<any>({
    name: "",
  });
  let formData = new FormData();
  const formik = useFormik({
    initialValues: categoryForEdit,
    validationSchema: CategorySchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      !updateFile && formData.append("name", values.name);
      !updateFile && formData.append("avatar_category_id", selectedCategory);
      formData.append(
        "file",
        showCover?.length > 0 ? showCover[0] : avatar?.file
      );
      try {
        if (avatar) {
          if (updateFile) {
            Api()
              .avatars.updateAvatarFile(avatar?.id, formData)
              .then((res) => {
                if (res) {
                  Swal.fire({
                    position: "center",
                    icon: "success",
                    title: res?.message,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  dispatch(setRefresh());
                  dispatch(closeModal());
                } else
                  Swal.fire({
                    icon: "error",
                    title: `#${res?.code}`,
                    text: res?.message,
                  });
              });
          } else {
            const body = {
              name: values.name,
              avatar_category_id: selectedCategory,
            };

            Api()
              .avatars.updateAvatar(avatar?.id, updateFile ? formData : body)
              .then((res) => {
                if (res) {
                  Swal.fire({
                    position: "center",
                    icon: "success",
                    title: res?.message,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  dispatch(setRefresh());
                  dispatch(closeModal());
                } else
                  Swal.fire({
                    icon: "error",
                    title: `#${res?.code}`,
                    text: res?.message,
                  });
              });
          }
        } else {
          Api()
            .avatars.addAvatar(formData)
            .then((res) => {
              if (res) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: res?.message,
                  showConfirmButton: false,
                  timer: 1500,
                });
                dispatch(setRefresh());
                dispatch(closeModal());
              } else
                Swal.fire({
                  icon: "error",
                  title: `#${res?.code}`,
                  text: res?.message,
                });
            });
        }
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (avatar) {
      formik.setValues(avatar);
    }
    {
      console.log(selectedCategory);
    }
    Api()
      .avatars.getAvatarCategories()
      .then(({ data }) => setCategories(data));
  }, []);
  return (
    <Modal show={modalActive} onHide={() => dispatch(closeModal())}>
      <Modal.Body>
        <ModalHeader
          handleClose={() => dispatch(closeModal())}
          titleHeader={"Avatar"}
        />
        <form
          id="kt_modal_add_user_form"
          onSubmit={formik.handleSubmit}
          className="form"
          noValidate
        >
          {/* begin::Scroll */}
          <div
            className="d-flex flex-column scroll-y me-n7 pe-7 "
            id="kt_modal_add_user_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_add_user_header"
            data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
            data-kt-scroll-offset="300px"
          >
            {!updateFile && (
              <div className="row mb-7 d-flex">
                <div className="col-xs-12 col-md-6">
                  <label className="required fw-bold fs-6 mb-2">
                    Avatar İsmi
                  </label>

                  <input
                    placeholder=""
                    {...formik.getFieldProps("name")}
                    type="text"
                    name="name"
                    className={clsx(
                      "form-control form-control-solid mb-3 mb-lg-0",
                      {
                        "is-invalid": formik.touched.name && formik.errors.name,
                      },
                      {
                        "is-valid": formik.touched.name && !formik.errors.name,
                      }
                    )}
                    autoComplete="off"
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">
                          {formik.errors.name?.toString()}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-xs-12 col-md-6  mb-7">
                  <label className="required fw-bold fs-6 mb-2">
                    Avatar Kategorisi
                  </label>
                  <select
                    className="form-select form-select-solid"
                    data-control="select2"
                    data-hide-search="true"
                    name="avatar_category_id"
                    onChange={(e) => setSelectedCategory(e?.target?.value)}
                    defaultValue={selectedCategory}
                    value={selectedCategory}
                  >
                    <option disabled hidden>
                      Seçiniz
                    </option>

                    {categories?.map((item: any) => {
                      return (
                        <option key={item?.id} value={item?.id}>
                          {item?.name}
                        </option>
                      );
                    })}
                  </select>
                  {formik.touched.avatar_category_id &&
                    formik.errors.avatar_category_id &&
                    (typeof formik.errors.avatar_category_id === "string" ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">
                            {formik.errors.avatar_category_id}
                          </span>
                        </div>
                      </div>
                    ) : null)}
                </div>
              </div>
            )}

            {!avatar && (
              <div className="fv-row mb-8">
                <AddPhoto
                  file={showCover}
                  setFile={setShowCover}
                  text={"Resim Seç"}
                  key={1}
                />
              </div>
            )}
            {updateFile && (
              <div className="fv-row mb-8">
                <AddPhoto
                  file={showCover}
                  setFile={setShowCover}
                  text={"Resim Seç"}
                  key={2}
                />
              </div>
            )}

            <div className="text-center pt-15">
              <button
                onClick={() => dispatch(closeModal())}
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
              >
                İptal
              </button>

              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={
                  formik.isSubmitting || !formik.isValid || !formik.touched
                }
              >
                <span className="indicator-label">Gönder</span>
                {formik.isSubmitting && (
                  <span className="indicator-progress">
                    Lütfen Bekleyin...{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddAvatar;
