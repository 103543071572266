export const CategoryApi = (instance: any) => ({
  async getCategories() {
    const {data} = await instance.get('/categories?list_all=1')
    return data
  },
  async deleteCategory(category_id: any) {
    const {data} = await instance.delete(`/categories/${category_id}`)
    return data
  },
  async addCategory(category: any) {
    const {data} = await instance.post('/categories', category)
    return data
  },
  async updateCategory(category_id: any, category: any) {
    const {data} = await instance.put(`/categories/${category_id}`, category)
    return data
  },
  async updateBanner(category_id: any, file: any) {
    const {data} = await instance.post(`/categories/${category_id}/banner`, file)
    return data
  },
})
