import { createSlice } from "@reduxjs/toolkit";

interface CategoryState {
  isLoading: boolean;
  error: string;
  modalActive: boolean;
  refresh: boolean;
  bannerModal: boolean;
  update: boolean;
}

const initialState: CategoryState = {
  isLoading: false,
  error: "",
  modalActive: false,
  refresh: false,
  bannerModal: false,
  update: false,
};

export const CategorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    openModal: (state) => {
      state.modalActive = true;
    },
    closeModal: (state) => {
      state.modalActive = false;
      state.update = false;
    },
    setRefresh: (state) => {
      state.refresh = !state.refresh;
    },
    setBannerModal: (state) => {
      state.bannerModal = !state.bannerModal;
    },
    setUpdate: (state) => {
      state.update = true;
    },
  },
});

export const { openModal, closeModal, setRefresh, setUpdate, setBannerModal } =
  CategorySlice.actions;

export default CategorySlice.reducer;
