import { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import { ModalHeader } from "../../../Modals/ModalHeader";
import clsx from "clsx";
import { useAppSelector } from "../../../../setup/hooks/redux";
import { closeModal, setRefresh } from "../reducers/CategorySlice";
import { useDispatch } from "react-redux";
import { Api } from "../../../Services/api";
import Swal from "sweetalert2";
import { AddPhoto } from "../../components/AddPhoto";

type Props = {
  category: any;
};

const CategorySchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "En az 3 karakter olmalı!")
    .required("Zorunlu alan"),
  order: Yup.number().required("Zorunlu alan"),
  description: Yup.string().required("Zorunlu alan"),
  // is_active: Yup.string().required('Zorunlu alan'),
});

const AddCategory: FC<Props> = ({ category }) => {
  // states
  const [showCover, setShowCover] = useState<any>([]);
  const [activity, setActivity] = useState(category?.is_active || "");
  const { modalActive, update } = useAppSelector((state) => state.category);
  const dispatch = useDispatch();
  const [categoryForEdit] = useState<any>({
    name: "",
  });

  let formData = new FormData();
  const formik = useFormik({
    initialValues: categoryForEdit,
    validationSchema: CategorySchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      // formData.append('name', values.name)
      // formData.append('order', values.order)
      // formData.append('description', values.description)
      // formData.append('is_active', activity)
      formData.append("banner", showCover[0]);
      const body = {
        name: values.name,
        order: values.order,
        description: values.description,
        is_active: activity,
      };
      try {
        if (update) {
          Api()
            .categories.updateCategory(category?.id, body)
            .then((res) => {
              if (res) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: res?.message,
                  showConfirmButton: false,
                  timer: 1500,
                });
                dispatch(setRefresh());
                dispatch(closeModal());
              } else
                Swal.fire({
                  icon: "error",
                  title: `#${res?.code}`,
                  text: res?.message,
                });
            });
        } else {
          Api()
            .categories.addCategory(body)
            .then((res) => {
              if (res) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: res?.message,
                  showConfirmButton: false,
                  timer: 1500,
                });
                dispatch(setRefresh());
                dispatch(closeModal());
              } else
                Swal.fire({
                  icon: "error",
                  title: `#${res?.code}`,
                  text: res?.message,
                });
            });
        }
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (category) {
      formik.setValues(category);
    }
  }, []);
  return (
    <Modal show={modalActive} onHide={() => dispatch(closeModal())}>
      <Modal.Body>
        <ModalHeader
          handleClose={() => dispatch(closeModal())}
          titleHeader={"Kategori"}
        />
        <form
          id="kt_modal_add_user_form"
          onSubmit={formik.handleSubmit}
          className="form"
          noValidate
        >
          {/* begin::Scroll */}
          <div
            className="d-flex flex-column scroll-y me-n7 pe-7 "
            id="kt_modal_add_user_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_add_user_header"
            data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
            data-kt-scroll-offset="300px"
          >
            <div className="row mb-7 d-flex">
              <div className="col-xs-12 col-md-6">
                <label className="required fw-bold fs-6 mb-2">
                  Kategori İsmi
                </label>
                <input
                  placeholder=""
                  {...formik.getFieldProps("name")}
                  type="text"
                  name="name"
                  className={clsx(
                    "form-control form-control-solid mb-3 mb-lg-0",
                    { "is-invalid": formik.touched.name && formik.errors.name },
                    {
                      "is-valid": formik.touched.name && !formik.errors.name,
                    }
                  )}
                  autoComplete="off"
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.name?.toString()}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-xs-12 col-md-6">
                <label className="required fw-bold fs-6 mb-2">
                  Kategori Sırası
                </label>
                <input
                  placeholder=""
                  {...formik.getFieldProps("order")}
                  type="text"
                  name="order"
                  className={clsx(
                    "form-control form-control-solid mb-3 mb-lg-0",
                    {
                      "is-invalid": formik.touched.order && formik.errors.order,
                    },
                    {
                      "is-valid": formik.touched.order && !formik.errors.order,
                    }
                  )}
                  autoComplete="off"
                  disabled={formik.isSubmitting}
                />
                {formik.touched.order && formik.errors.order && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">
                        {formik.errors.order?.toString()}
                      </span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
            </div>

            <div className="row mb-7 d-flex">
              <div className="col-xs-12 col-md-12">
                <label className="required fw-bold fs-6 mb-2">Açıklama</label>
                <textarea
                  placeholder=""
                  {...formik.getFieldProps("description")}
                  name="description"
                  rows={4}
                  className={clsx(
                    "form-control form-control-solid mb-3 mb-lg-0",
                    {
                      "is-invalid":
                        formik.touched.description && formik.errors.description,
                    },
                    {
                      "is-valid":
                        formik.touched.description &&
                        !formik.errors.description,
                    }
                  )}
                  autoComplete="off"
                ></textarea>
                {formik.touched.description && formik.errors.description && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">
                        {formik.errors.description.toString()}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="row mb-7 d-flex">
              <div className="col-xs-12 col-md-6  mb-7">
                <label className="required fw-bold fs-6 mb-2">Yayınla</label>
                <select
                  className="form-select form-select-solid"
                  data-control="select2"
                  data-hide-search="true"
                  data-placeholder="Şehir Seç"
                  name="is_active"
                  onChange={(e) => setActivity(e?.target?.value)}
                  defaultValue={activity}
                >
                  <option selected disabled hidden>
                    Seçiniz
                  </option>

                  <option value={"1"}>Evet</option>
                  <option value={"0"}>Hayır</option>
                </select>
                {formik.touched.is_active &&
                  formik.errors.is_active &&
                  (typeof formik.errors.is_active === "string" ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.is_active}</span>
                      </div>
                    </div>
                  ) : null)}
              </div>
            </div>
            <div className="fv-row mb-8">
              {/* <label className='fs-7 fw-bold mb-2'>Cover Resim Seç</label> */}
              {!update && (
                <AddPhoto
                  file={showCover}
                  setFile={setShowCover}
                  text={"Resim Seç"}
                  key={1}
                />
              )}
            </div>
            <div className="text-center pt-15">
              <button
                onClick={() => dispatch(closeModal())}
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
              >
                İptal
              </button>

              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={
                  formik.isSubmitting || !formik.isValid || !formik.touched
                }
              >
                <span className="indicator-label">Gönder</span>
                {formik.isSubmitting && (
                  <span className="indicator-progress">
                    Lütfen Bekleyin...{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddCategory;
