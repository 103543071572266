import { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import { ModalHeader } from "../../../Modals/ModalHeader";
import clsx from "clsx";
import { useAppSelector } from "../../../../setup/hooks/redux";
import { useDispatch } from "react-redux";
import { Api } from "../../../Services/api";
import Swal from "sweetalert2";
import { changePhotoModal, setRefresh } from "../reducers/TeacherSlice";
import Multiselect from "multiselect-react-dropdown";
import { AddPhoto } from "../../components/AddPhoto";

type Props = {
  teacher: any;
};

const TeacherSchema = Yup.object().shape({});

const UpdatePhoto: FC<Props> = ({ teacher }) => {
  // states
  const [photo, setPhoto] = useState<any>([]);
  const { photoModal } = useAppSelector((state) => state.teacher);
  const dispatch = useDispatch();
  const [categoryForEdit] = useState<any>({});

  let formData = new FormData();
  const formik = useFormik({
    initialValues: categoryForEdit,
    validationSchema: TeacherSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      formData.append("photo", photo[0]);
      try {
        Api()
          .teachers.updatePhoto(teacher?.slug, formData)
          .then((res) => {
            if (res) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: res?.message,
                showConfirmButton: false,
                timer: 1500,
              });
              dispatch(setRefresh());
              dispatch(changePhotoModal());
            } else
              Swal.fire({
                icon: "error",
                title: `#${res?.code}`,
                text: res?.message,
              });
          });
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Modal show={photoModal} onHide={() => dispatch(changePhotoModal())}>
      <Modal.Body>
        <ModalHeader
          handleClose={() => dispatch(changePhotoModal())}
          titleHeader={"Eğitmen"}
        />
        <form
          id="kt_modal_add_user_form"
          onSubmit={formik.handleSubmit}
          className="form"
          noValidate
        >
          {/* begin::Scroll */}
          <div
            className="d-flex flex-column scroll-y me-n7 pe-7 "
            id="kt_modal_add_user_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_add_user_header"
            data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
            data-kt-scroll-offset="300px"
          >
            <div className="fv-row mb-8">
              <AddPhoto
                file={photo}
                setFile={setPhoto}
                text="Resim Seç"
                key={1}
              />
            </div>

            <div className="text-center pt-15">
              <button
                onClick={() => dispatch(changePhotoModal())}
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
              >
                İptal
              </button>

              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={
                  formik.isSubmitting || !formik.isValid || !formik.touched
                }
              >
                <span className="indicator-label">Gönder</span>
                {formik.isSubmitting && (
                  <span className="indicator-progress">
                    Lütfen Bekleyin...{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdatePhoto;
