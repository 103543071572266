import React, {FC} from 'react'
import style from './style.module.css'
type Props = {
  item: any
}
export const AboutLesson: FC<Props> = ({item}) => {
  return (
    <div className='container mb-5'>
      <div className='row'>
        <div className='col-sm-12 overflow-hidden'>
          <div id='favorites-contens'>
            <div className='mt-5'>
              <div className='row'>
                <div className='col-md-7 col-lg-7 col-xl-7'>
                  <div
                    className={style.wsAbout}
                    style={{
                      background: `url(${item?.banner.path})`,
                      maxWidth: '100%',
                    }}
                  >
                    {/* <img src={item?.banner?.path} height="100%" /> */}
                    {/* <div className={style.wsPlay}>
                      <div className={style.wsPlayIcon} style={{width: '70px', height: '70px', zIndex: '99'}}></div>
                    </div> */}
                  </div>
                  <div className='position-absolute top-4'></div>
                </div>
                <div className='col-md-5 col-lg-5 col-xl-5 mt-10'>
                  <h1 className='h2 slider-text title text-uppercase fs-2x'>{item?.name}</h1>
                  <div className='d-flex flex-wrap align-items-center'>
                    <p className='mt-3 mb-3 fs-5' data-iq-gsap='onStart' data-iq-position-y='80' data-iq-delay='0.8'>
                      {item?.description}
                    </p>
                  </div>
                  <div className='row'>
                    <div className='col-md-12 col-lg-12 col-xl-12'>
                      <div className='trending-list mb-3 fs-5'>
                        <div className='row'>
                          <div className=' col-6 '>
                            <div className='text-muted '>Eğitmen:</div>
                          </div>
                          <div className='col-6 text-left'>
                            <div className=' text-left  fw-bold'>{item?.teacher?.name}</div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-6 '>
                            <div className='text-muted '>Kategori:</div>
                          </div>
                          <div className='col-6'>
                            <div className=' fw-bold'>{item?.category?.name}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
