/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { toAbsoluteUrl } from "../../../helpers";
import { PageTitle } from "./page-title/PageTitle";
import { HeaderUserMenu } from "../../../partials";
import { useLayout } from "../../core";
import { UserModel } from "../../../../app/modules/auth/models/UserModel";
import { RootState } from "../../../../setup";
import { shallowEqual, useSelector } from "react-redux";

const HeaderTopbar: React.FC<{ children?: React.ReactNode }> = () => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { classes } = useLayout();
  return (
    <div
      className={`${classes.headerContainer} py-6 py-lg-0 d-flex  flex-sm-row align-items-center justify-content-between`}
    >
      <PageTitle />

      <div className="d-flex align-items-center overflow-auto cursor-pointer ">
        <div className="d-flex">
          <div className="d-flex align-items-center ">
            {/* eslint-disable-next-line */}
            <div
              className="symbol symbol-circle "
              data-kt-menu-attach="parent"
              data-kt-menu-flip="bottom"
              data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
              data-kt-menu-overflow="false"
              data-kt-menu-placement="bottom-end"
            >
              {user.email == "gurhan@ecodation.com" ? (
                <img
                  // style={{width: '100%'}}
                  className="symbol symbol-circle"
                  src={toAbsoluteUrl("/media/avatars/huseyin_avatar.jpeg")}
                  alt="avatar"
                />
              ) : (
                <div
                  className="symbol-label text-inverse-primary fw-bolder fs-1 h-50px w-50px rounded-circle"
                  style={{ backgroundColor: "#565674" }}
                >
                  {user?.name?.substring(0, 1).toUpperCase()}
                  {user?.name.substring(1, 2).toUpperCase()}
                </div>
              )}

              {/* <div className='symbol-label bg-primary text-inverse-primary fw-bolder fs-3 '>
                {user?.firstname.slice(0, 1)}
                {user?.lastname.slice(0, 1)}
              </div> */}
            </div>

            <HeaderUserMenu />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderTopbar;
