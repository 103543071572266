import {createSlice} from '@reduxjs/toolkit'
import {stat} from 'fs'

interface CategoryState {
  isLoading: boolean
  error: string
  modalActive: boolean
  refresh: boolean
  updateFile: boolean
  bannerModal: boolean
}

const initialState: CategoryState = {
  isLoading: false,
  error: '',
  modalActive: false,
  refresh: false,
  updateFile: false,
  bannerModal: false,
}

export const AvatarSlice = createSlice({
  name: 'avatar',
  initialState,
  reducers: {
    openModal: (state) => {
      state.modalActive = true
    },
    closeModal: (state) => {
      state.modalActive = false
      state.updateFile = false
    },
    setRefresh: (state) => {
      state.refresh = !state.refresh
    },
    setUpdateFile: (state) => {
      state.updateFile = true
    },
    setBannerModal: (state) => {
      state.bannerModal = !state.bannerModal
    },
  },
})

export const {openModal, closeModal, setRefresh, setUpdateFile, setBannerModal} = AvatarSlice.actions

export default AvatarSlice.reducer
