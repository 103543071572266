import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {openTeacherModal} from './reducers/TeacherSlice'
import {Api} from '../../Services/api'
import {useAppSelector} from '../../../setup/hooks/redux'
import AddTeacher from './modals/AddTeacher'
import Pagination from '../components/Pagination'
import {TeacherItem} from './components/TeacherItem'
import UpdateLogo from './modals/UpdateLogo'
import UpdatePhoto from './modals/UpdatePhoto'

const Teachers = () => {
  const dispatch = useDispatch()
  const {modalActive, refresh, logoModal, photoModal} = useAppSelector((state) => state.teacher)
  const [record, setRecord] = useState<any>()
  const [teachers, setTeachers] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const [limit, setLimit] = useState(8)
  const [total, setTotal] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  useEffect(() => {
    Api()
      .teachers.getTeachers(currentPage, limit)
      .then((res) => {
        setTotal(res?.meta?.total)
        setTeachers(res?.data)
        setLoading(false)
      })
  }, [refresh, currentPage])
  return (
    <div>
      {modalActive && <AddTeacher teacher={record} />}
      {logoModal && <UpdateLogo teacher={record} />}
      {photoModal && <UpdatePhoto teacher={record} />}
      <div className='card-header align-items-center py-5 gap-2 gap-md-5'>
        <div className='card-toolbar d-flex justify-content-end gap-5'>
          <div
            onClick={() => {
              dispatch(openTeacherModal())
            }}
            className='btn btn-primary'
          >
            Ekle
          </div>
        </div>
      </div>
      <div className='card-body pt-0'>
        {loading ? (
          <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
            <div className='spinner-border' role='status'>
              <span className='visually-hidden'>Yükleniyor...</span>
            </div>
          </div>
        ) : (
          <div className='position-relative min-h-300px  scroll-x'>
            <div className='row d-flex'>
              {teachers?.map((item: any) => {
                return <TeacherItem key={item?.id} item={item} setRecord={setRecord} />
              })}
            </div>
          </div>
        )}
        <Pagination
          onPageChange={(page: number) => setCurrentPage(page)}
          totalCount={total}
          currentPage={currentPage}
          pageSize={limit}
          loading={loading}
        />
      </div>
    </div>
  )
}

export default Teachers
