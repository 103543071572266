import { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import { ModalHeader } from "../../../Modals/ModalHeader";
import clsx from "clsx";
import { useAppSelector } from "../../../../setup/hooks/redux";
import { useDispatch } from "react-redux";
import Dropzone from "react-dropzone";
import { Api } from "../../../Services/api";
import Swal from "sweetalert2";
import { closeModal, setRefresh } from "../reducers/ClassroomSlice";
import { AddPhoto } from "../../components/AddPhoto";

type Props = {
  classroom: any;
  classroom_slug: string;
  classroom_id: any;
};

const ClassroomSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "En az 3 karakter olmalı!")
    .required("Zorunlu alan"),
  description: Yup.string().min(8).required("Zorunlu alan"),
  order: Yup.string(),
});
const UpdateClassroomSchema = Yup.object().shape({});

const AddLesson: FC<Props> = ({ classroom, classroom_slug, classroom_id }) => {
  // states
  const [photo, setPhoto] = useState<any>([]);
  const [videos, setVideos] = useState<any>([]);
  const [selectedVideo, setSelectedVideo] = useState(classroom?.raw_file_path);
  const { modalActive, update } = useAppSelector(
    (state) => state.classroomDetail
  );
  const dispatch = useDispatch();
  const [classroomForEdit] = useState<any>({
    name: "",
  });
  let formData = new FormData();
  const formik = useFormik({
    initialValues: classroomForEdit,
    validationSchema: classroom ? UpdateClassroomSchema : ClassroomSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      formData.append("name", values.name);
      formData.append("order", values.order);
      formData.append("description", values.description);
      formData.append("thumbnail", photo[0]);
      formData.append("tags", values?.tags);
      formData.append(
        "download_url",
        values?.download_url ? values?.download_url : null
      );
      formData.append(
        "podcast_file_path",
        values?.podcast_file_path ? values?.podcast_file_path : null
      );
      formData.append("file", selectedVideo);
      formData.append("classroom_id", classroom_id);

      try {
        if (update) {
          console.log(selectedVideo);
          const body = {
            ...values,
            classroom_id: classroom_id,
            file: selectedVideo,
          };

          Api()
            .classromms.updateVideo(classroom?.slug, body)
            .then((res) => {
              console.log(res);
              if (res?.data?.success) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: res?.data?.message,
                  showConfirmButton: false,
                  timer: 1500,
                });
                dispatch(setRefresh());
                dispatch(closeModal());
              } else {
                Swal.fire({
                  icon: "error",
                  title: `#${res?.data?.message}`,
                  text: res?.statusText,
                });
              }
            });
        } else {
          Api()
            .classromms.addVideo(formData)
            .then((res) => {
              if (res?.data?.success) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: res?.data?.message,
                  showConfirmButton: false,
                  timer: 1500,
                });
                dispatch(setRefresh());
                dispatch(closeModal());
              } else {
                Swal.fire({
                  icon: "error",
                  title: `#${res?.data?.message}`,
                  text: res?.statusText,
                });
              }
            });
        }
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    let tempTags = "";
    if (update) {
      classroom?.tags.map((tag: any) => {
        tempTags += tag?.name + ",";
      });
      formik.setValues({ ...classroom, tags: tempTags });
    }
    console.log(classroom);
    Api()
      .classromms.getLessonVideos(classroom_slug)
      .then(({ data }) => {
        setVideos(data?.data);
      });
  }, []);
  return (
    <Modal show={modalActive} onHide={() => dispatch(closeModal())}>
      <Modal.Body>
        <ModalHeader
          handleClose={() => dispatch(closeModal())}
          titleHeader={"Eğitim"}
        />
        <form
          id="kt_modal_add_user_form"
          onSubmit={formik.handleSubmit}
          className="form"
          noValidate
        >
          {/* begin::Scroll */}
          <div
            className="d-flex flex-column scroll-y me-n7 pe-7 "
            id="kt_modal_add_user_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_add_user_header"
            data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
            data-kt-scroll-offset="300px"
          >
            <div className="row mb-7 d-flex">
              <div className="col-xs-12 col-md-12">
                <label className="required fw-bold fs-6 mb-2">Başlık</label>

                <input
                  placeholder=""
                  {...formik.getFieldProps("name")}
                  type="text"
                  name="name"
                  className={clsx(
                    "form-control form-control-solid mb-3 mb-lg-0",
                    { "is-invalid": formik.touched.name && formik.errors.name },
                    {
                      "is-valid": formik.touched.name && !formik.errors.name,
                    }
                  )}
                  autoComplete="off"
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.name?.toString()}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="row mb-7 d-flex">
              <div className="col-xs-12 col-md-12">
                <label className="required fw-bold fs-6 mb-2">Açıklama</label>
                <textarea
                  placeholder=""
                  {...formik.getFieldProps("description")}
                  name="description"
                  rows={4}
                  className={clsx(
                    "form-control form-control-solid mb-3 mb-lg-0",
                    {
                      "is-invalid":
                        formik.touched.description && formik.errors.description,
                    },
                    {
                      "is-valid":
                        formik.touched.description &&
                        !formik.errors.description,
                    }
                  )}
                  autoComplete="off"
                ></textarea>
                {formik.touched.description && formik.errors.description && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">
                        {formik.errors.description.toString()}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="row mb-7 d-flex">
              <div className="col-xs-12 col-md-12">
                <label className="required fw-bold fs-6 mb-2">Sırası</label>

                <input
                  placeholder=""
                  {...formik.getFieldProps("order")}
                  type="text"
                  name="order"
                  className={clsx(
                    "form-control form-control-solid mb-3 mb-lg-0",
                    {
                      "is-invalid": formik.touched.order && formik.errors.order,
                    },
                    {
                      "is-valid": formik.touched.order && !formik.errors.order,
                    }
                  )}
                  autoComplete="off"
                />
                {formik.touched.order && formik.errors.order && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">
                        {formik.errors.order?.toString()}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="row mb-7 d-flex">
              <div className="col-xs-12 col-md-12">
                <label className="required fw-bold fs-6 mb-2">Tags</label>

                <input
                  placeholder=""
                  {...formik.getFieldProps("tags")}
                  type="text"
                  name="tags"
                  className={clsx(
                    "form-control form-control-solid mb-3 mb-lg-0",
                    { "is-invalid": formik.touched.tags && formik.errors.tags },
                    {
                      "is-valid": formik.touched.tags && !formik.errors.tags,
                    }
                  )}
                  autoComplete="off"
                />
                {formik.touched.tags && formik.errors.tags && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.tags?.toString()}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="row mb-7 d-flex">
              <div className="col-xs-12 col-md-12">
                <label className="required fw-bold fs-6 mb-2">
                  İndirme Linki
                </label>

                <input
                  placeholder=""
                  {...formik.getFieldProps("download_url")}
                  type="text"
                  name="download_url"
                  className={clsx(
                    "form-control form-control-solid mb-3 mb-lg-0",
                    {
                      "is-invalid":
                        formik.touched.download_url &&
                        formik.errors.download_url,
                    },
                    {
                      "is-valid":
                        formik.touched.download_url &&
                        !formik.errors.download_url,
                    }
                  )}
                  autoComplete="off"
                />
                {formik.touched.download_url && formik.errors.download_url && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">
                        {formik.errors.download_url?.toString()}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="row mb-7 d-flex">
              <div className="col-xs-12 col-md-12">
                <label className="required fw-bold fs-6 mb-2">
                  Podcast Linki
                </label>

                <input
                  placeholder=""
                  {...formik.getFieldProps("podcast_file_path")}
                  type="text"
                  name="podcast_file_path"
                  className={clsx(
                    "form-control form-control-solid mb-3 mb-lg-0",
                    {
                      "is-invalid":
                        formik.touched.podcast_file_path &&
                        formik.errors.podcast_file_path,
                    },
                    {
                      "is-valid":
                        formik.touched.podcast_file_path &&
                        !formik.errors.podcast_file_path,
                    }
                  )}
                  autoComplete="off"
                />
                {formik.touched.podcast_file_path &&
                  formik.errors.podcast_file_path && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">
                          {formik.errors.podcast_file_path?.toString()}
                        </span>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div className="row mb-7 d-flex">
              <div className="col-xs-12 col-md-12  mb-7">
                <label className="required fw-bold fs-6 mb-2">Video Seç</label>
                <select
                  className="form-select form-select-solid"
                  data-control="select2"
                  data-hide-search="true"
                  name="file"
                  onChange={(e) => setSelectedVideo(e?.target?.value)}
                  defaultValue={selectedVideo}
                  value={selectedVideo}
                >
                  <option disabled hidden>
                    Seçiniz
                  </option>

                  {videos?.map((item: any, index: any) => {
                    return (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    );
                  })}
                </select>
                {formik.touched.teacher_id &&
                  formik.errors.teacher_id &&
                  (typeof formik.errors.teacher_id === "string" ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.teacher_id}</span>
                      </div>
                    </div>
                  ) : null)}
              </div>
            </div>

            {!update && (
              <>
                <div className="fv-row mb-8">
                  <AddPhoto
                    file={photo}
                    setFile={setPhoto}
                    text="Resim Seç"
                    key={4}
                  />
                </div>
              </>
            )}

            <div className="text-center pt-15">
              <button
                onClick={() => dispatch(closeModal())}
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
              >
                İptal
              </button>

              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={
                  formik.isSubmitting || !formik.isValid || !formik.touched
                }
              >
                <span className="indicator-label">Gönder</span>
                {formik.isSubmitting && (
                  <span className="indicator-progress">
                    Lütfen Bekleyin...{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddLesson;
