import { FC } from "react";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { Api } from "../../../Services/api";
import {
  openModal,
  setRefresh,
  setThumbnailModal,
  setUpdate,
} from "../reducers/ClassroomSlice";
import style from "./style.module.css";
import { toAbsoluteUrl } from "../../../../_theme/helpers";
import { Dropdown } from "react-bootstrap";

type Props = {
  item: any;
  setRecord: any;
  index: any;
};
export const DetailItem: FC<Props> = ({ item, setRecord, index }) => {
  const dispatch = useDispatch();
  function deleteItem() {
    Swal.fire({
      title: "Kayıt silinecektir emin misiniz?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Vazgeç",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        Api()
          .classromms.deleteVideo(item?.slug)
          .then((res) => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: res?.message,
              showConfirmButton: false,
              timer: 1500,
            });
            dispatch(setRefresh());
          });
      }
    });
  }
  return (
    <>
      <div className="row mt-4">
        <div className="col-12 col-md-4 ">
          <div
            className={`${style.wsAboutList}`}
            style={{
              background: `url(${
                item?.thumbnail
                  ? item?.thumbnail?.path
                  : toAbsoluteUrl("/media/product/about.png")
              })`,
              maxWidth: "100%",
            }}
          >
            <div className={`${style.wsCardImgBg}`}></div>
            <div className={`${style.wsPlay} `}></div>
            <div className={style.time}>{item?.duration}</div>
          </div>
        </div>
        <div className="col-9 col-md-7 mt-3">
          <div className="">
            <div className="text-gray-800 fw-bolder fs-2">
              {index}.{item?.name}
            </div>
            <div className="fs-5">{item?.description}</div>
          </div>
        </div>{" "}
        <div className="col-2  col-md-1 col-sm-1 mt-3 ">
          <Dropdown
            className=""
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >
            <Dropdown.Toggle
              variant="primary"
              id="dropdown-basic"
            ></Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                {" "}
                <div className="menu-item px-3">
                  <div
                    className="menu-link px-3"
                    onClick={() => {
                      dispatch(openModal());
                      dispatch(setUpdate());
                      setRecord(item);
                    }}
                  >
                    Düzenle
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item>
                {" "}
                <div className="menu-item px-3">
                  <div
                    className="menu-link px-3"
                    onClick={() => {
                      dispatch(setThumbnailModal());
                      setRecord(item);
                    }}
                  >
                    Resmi Değiştir
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item>
                {" "}
                <div className="menu-item px-3" onClick={deleteItem}>
                  <div className="menu-link px-3 text-danger">Sil</div>
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <td className="text-end"></td>
      </div>
    </>
  );
};
