export const TeacherApi = (instance: any) => ({
  async getTeachers(currentPage?: any, limit?: any) {
    const {data} = await instance.get(`teachers?page[number]=${currentPage}&page[size]=${limit}`)
    return data
  },
  async addTeacher(teacher: any) {
    const {data} = await instance.post('teachers', teacher)
    return data
  },
  async deleteTeacher(teacher_id: any) {
    const {data} = instance.delete(`teachers/${teacher_id}`)
    return data
  },
  async updateTeacher(teacher_id: any, teacher: any) {
    const {data} = instance.put(`teachers/${teacher_id}`, teacher)
    return data
  },
  async updatePhoto(teacher_id: any, photo: any) {
    const {data} = await instance.post(`teachers/${teacher_id}/photo`, photo)
    return data
  },
  async updateLogo(teacher_id: any, logo: any) {
    const {data} = await instance.post(`teachers/${teacher_id}/logos`, logo)
    return data
  },
})
