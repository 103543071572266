export const DashboardApi = (instance: any) => ({
  async getTotalUsers() {
    const { data } = await instance.get("widgets/total-users");
    return data;
  },
  async getTotalTeachers() {
    const { data } = await instance.get("widgets/total-teachers");
    return data;
  },
  async getTotalClassrooms() {
    const { data } = await instance.get("widgets/total-classrooms");
    return data;
  },
  async getTotalViewCount() {
    const { data } = await instance.get("widgets/total-view-count");
    return data;
  },
  async getTodaysRevenue() {
    const { data } = await instance.get("widgets/today-revenue");
    return data;
  },
  async getMonthlyRevenue() {
    const { data } = await instance.get("widgets/month-revenue");
    return data;
  },
  async getDailyViewCount() {
    const { data } = await instance.get("widgets/total-visitor-count");
    return data;
  },
  async getVisitPercantage() {
    const { data } = await instance.get("widgets/visit-percentage");
    return data;
  },
  async getWidgetImage() {
    const { data } = await instance.get("assets/wise.jpg");
    return data;
  },
});
