import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  return (
    <>
      <AsideMenuItem to='/dashboard' title='Anasayfa' fontIcon='fas fa-circle'></AsideMenuItem>
      <AsideMenuItem to='/teachers' title={'Eğitmenler'} fontIcon='fas fa-circle'></AsideMenuItem>
      <AsideMenuItem to='/classrooms' title='Eğitimler' fontIcon='fas fa-circle'></AsideMenuItem>
      <AsideMenuItem to='/categories' title='Kategoriler' fontIcon='fas fa-circle'></AsideMenuItem>
      <AsideMenuItem to='/avatars' title='Avatarlar' fontIcon='fas fa-circle'></AsideMenuItem>
      <AsideMenuItem to='/avatar-categories' title='Avatar Kategorileri' fontIcon='fas fa-circle'></AsideMenuItem>
    </>
  )
}
