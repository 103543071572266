import React, { useEffect, useState } from "react";
import { EngageWidget1 } from "./widgets/EngageWidget1";
import { Api } from "../../Services/api";
import { CardsWidget2 } from "./widgets/CardsWidget2";
import { CardsWidget1 } from "./widgets/CardsWidget1";
import { ChartsWidget1 } from "./widgets/ChartsWidget1";
import { PieWidget } from "./widgets/PieWidget";
import { DateComponent } from "./widgets/DateComponent";

export const Dashboard = () => {
  const [totalUsers, setTotalUsers] = useState<any>();
  const [totalTeachers, setTotalTeachers] = useState<any>();
  const [totalClassrooms, setTotalClassrooms] = useState<any>();
  const [totalViewCount, setTotalViewCount] = useState<any>();

  useEffect(() => {
    Api()
      .dashboard.getTotalUsers()
      .then((data) => {
        setTotalUsers(data.data);
      });
    Api()
      .dashboard.getTotalTeachers()
      .then((data) => {
        setTotalTeachers(data?.data);
      });
    Api()
      .dashboard.getTotalClassrooms()
      .then((data) => {
        setTotalClassrooms(data?.data);
      });
    Api()
      .dashboard.getTotalViewCount()
      .then((data) => {
        setTotalViewCount(data?.data);
      });
  }, []);
  return (
    <>
      <div className="row gy-5 g-xl-10 mb-3">
        <div className="col-9"></div>
        <div className="col-3">
          {" "}
          {/* <DateComponent start_date='29.04.2024' end_date='06.05.2024' /> */}
        </div>
      </div>
      <div className="row gy-5 g-xl-10 mb-3">
        <div className="col-xl-12 mb-xl-10">
          {" "}
          {/* <ChartsWidget1 chartHeight={300} /> */}
        </div>
      </div>
      <div className="row gy-5 g-xl-10 mb-3">
        <div className="col-sm-6 col-xl-2 mb-xl-10">
          <CardsWidget2
            image={"media/dashboard/icons/total_user.svg"}
            count={totalUsers}
            text={"Toplam Kullanıcı"}
          />
        </div>
        <div className="col-sm-6 col-xl-2 mb-xl-10">
          <CardsWidget2
            image={"media/dashboard/icons/total_classroom.svg"}
            count={totalClassrooms}
            text={"Toplam Eğitim"}
          />
        </div>
        <div className="col-sm-6 col-xl-2 mb-xl-10">
          <CardsWidget2
            image={"media/dashboard/icons/total_teacher.svg"}
            count={totalTeachers}
            text={"Toplam Eğitmen"}
          />
        </div>
        <div className="col-sm-6 col-xl-2 mb-xl-10">
          <CardsWidget2
            image={"media/dashboard/icons/total_view.svg"}
            count={totalViewCount}
            text={"Toplam İzlenme"}
          />
        </div>
        <div className="col-xl-4 mb-xl-10">
          <CardsWidget1 chartHeight="105px" chartColor="#8F5FF4" />
        </div>
      </div>
      <div className="row gy-5 g-xl-10">
        <div className="col-xl-6 mb-5 mb-xl-10">
          <PieWidget />
        </div>
        <div className="col-xl-6 mb-5 mb-xl-10">
          <EngageWidget1 />
        </div>
      </div>
    </>
  );
};
