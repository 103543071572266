import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {openModal} from './reducers/CategorySlice'
import AddCategory from './modals/AddCategory'
import {Api} from '../../Services/api'
import CategoryItem from './components/CategoryItem'
import {useAppSelector} from '../../../setup/hooks/redux'
import UpdateBanner from './modals/UpdateBanner'

const Categories = () => {
  const dispatch = useDispatch()
  const {modalActive, refresh, bannerModal} = useAppSelector((state) => state.category)
  const [record, setRecord] = useState<any>()
  const [categories, setCategories] = useState<any>([])
  useEffect(() => {
    Api()
      .categories.getCategories()
      .then(({data}) => setCategories(data))
  }, [refresh])
  return (
    <div>
      {modalActive && <AddCategory category={record} />}
      {bannerModal && <UpdateBanner classroom={record} />}
      <div className='card card-flush'>
        <div className='card-header align-items-center py-5 gap-2 gap-md-5'>
          {/* <div className='card-title'>
            <div className='d-flex align-items-center position-relative my-1'>
              <span className='svg-icon svg-icon-1 position-absolute ms-4'>
                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
                  <rect
                    opacity='0.5'
                    x='17.0365'
                    y='15.1223'
                    width='8.15546'
                    height='2'
                    rx='1'
                    transform='rotate(45 17.0365 15.1223)'
                    fill='currentColor'
                  />
                  <path
                    d='M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z'
                    fill='currentColor'
                  />
                </svg>
              </span>

              <input
                type='text'
                data-kt-ecommerce-product-filter='search'
                className='form-control form-control-solid w-250px ps-14'
                placeholder='Search Product'
              />
            </div>
          </div> */}

          <div className='card-toolbar flex-row-fluid justify-content-end gap-5'>
            <div
              onClick={() => {
                setRecord(undefined)
                dispatch(openModal())
              }}
              className='btn btn-primary'
            >
              Ekle
            </div>
          </div>
        </div>
        <div className='card-body pt-0'>
          <div className='position-relative min-h-300px  scroll-x'>
            <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_ecommerce_products_table'>
              <thead>
                <tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
                  <th className='min-w-200px'>Kategori</th>

                  <th className='text-end min-w-70px'>İşlem</th>
                </tr>
              </thead>
              <tbody className='fw-bold text-gray-600'>
                {categories?.map((item: any) => {
                  return <CategoryItem key={item?.id} item={item} setRecord={setRecord} />
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Categories
