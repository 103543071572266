/* eslint-disable jsx-a11y/anchor-is-valid */
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as auth from "../../../../app/modules/auth/redux/AuthRedux";
import { UserModel } from "../../../../app/modules/auth/models/UserModel";
import { RootState } from "../../../../setup";
import { toAbsoluteUrl } from "../../../helpers";

const HeaderUserMenu = () => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const dispatch = useDispatch();
  const logout = () => {
    dispatch(auth.actions.logout());
  };

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px "
      data-kt-menu="true"
    >
      {/* begin::Menu item */}
      <div className="menu-item px-5">
        <div className="menu-content d-flex align-items-center px-3">
          {/* begin::Avatar */}
          {/* <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={toAbsoluteUrl('/media/avatars/300-1.jpg')} />
          </div> */}
          <div className="symbol symbol-50px me-2">
            {user.email == "gurhan@ecodation.com" ? (
              <img
                src={toAbsoluteUrl("/media/avatars/huseyin_avatar.jpeg")}
                style={{
                  height: "50px",
                  width: "50px",
                  borderRadius: "20% !important",
                }}
                className="rounded-circle"
              />
            ) : (
              <div
                className="symbol-label text-inverse-primary fw-bolder fs-1 h-50px w-50px rounded-circle"
                style={{ backgroundColor: "#565674" }}
              >
                {user?.name?.substring(0, 1).toUpperCase()}
                {user?.name.substring(1, 2).toUpperCase()}
              </div>
            )}
          </div>
          {/* end::Avatar */}

          {/* begin::Username */}
          <div className="d-flex flex-column" style={{ paddingRight: "30px" }}>
            <div className="fw-bolder d-flex align-items-center fs-5">
              {user.name}
              {/* <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span> */}
            </div>
            <a href="#" className="fw-bold text-muted text-hover-primary fs-8 ">
              {user.email}
            </a>
          </div>
          {/* end::Username */}
        </div>
      </div>
      <div className="separator my-2"></div>
      <div className="menu-item px-5">
        <a onClick={logout} className="menu-link px-5">
          Çıkış Yap
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
