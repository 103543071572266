import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {Modal} from 'react-bootstrap'
import {ModalHeader} from '../../../Modals/ModalHeader'
import clsx from 'clsx'
import {useAppSelector} from '../../../../setup/hooks/redux'
import {useDispatch} from 'react-redux'
import {Api} from '../../../Services/api'
import Swal from 'sweetalert2'
import {closeModal, setRefresh} from '../reducers/TeacherSlice'
import Multiselect from 'multiselect-react-dropdown'
import {AddPhoto} from '../../components/AddPhoto'

type Props = {
  teacher: any
}

const TeacherSchema = Yup.object().shape({
  name: Yup.string().min(3, 'En az 3 karakter olmalı!').required('Zorunlu alan'),
  //   order: Yup.number().required('Zorunlu alan'),
  description: Yup.string().required('Zorunlu alan'),
  // is_active: Yup.string().required('Zorunlu alan'),
})

const AddTeacher: FC<Props> = ({teacher}) => {
  // states
  const [showCover, setShowCover] = useState<any>([])
  const [logo, setLogo] = useState<any>([])
  const [keywords, setKeywords] = useState([])
  const [selectedKeywords, setSelectedKeywords] = useState<any>(teacher?.categories)
  const [errorsCover, setErrorsCover] = useState('')
  const {modalActive, update} = useAppSelector((state) => state.teacher)
  const dispatch = useDispatch()
  const [categoryForEdit] = useState<any>({
    name: '',
  })

  const onSelectKeyword = (selectedList: any, selectedItem: any) => {
    setSelectedKeywords(selectedList)
  }
  const onRemoveKeyword = (selectedList: any, removedItem: any) => {
    setSelectedKeywords(selectedList)
  }
  let formData = new FormData()
  const formik = useFormik({
    initialValues: categoryForEdit,
    validationSchema: TeacherSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      formData.append('name', values.name)
      formData.append('description', values.description)
      logo?.length > 0 && formData.append('logo', logo[0])
      showCover?.length > 0 && formData.append('photo', showCover[0])
      for (let i = 0; i < selectedKeywords.length; i++) {
        formData.append('categories[' + i + ']', selectedKeywords[i].id)
      }
      try {
        if (update) {
          const selectedIds = selectedKeywords.map(({id}: any) => id)
          const body = {
            name: values.name,
            description: values.description,
            categories: selectedIds,
          }
          Api()
            .teachers.updateTeacher(teacher?.slug, body)
            .then((res) => {
              console.log(res)
              if (res) {
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: res?.message,
                  showConfirmButton: false,
                  timer: 1500,
                })
                dispatch(setRefresh())
                dispatch(closeModal())
              } else
                Swal.fire({
                  icon: 'error',
                  title: `#${res?.code}`,
                  text: res?.message,
                })
            })
        } else {
          Api()
            .teachers.addTeacher(formData)
            .then((res) => {
              if (res) {
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: res?.message,
                  showConfirmButton: false,
                  timer: 1500,
                })
                dispatch(setRefresh())
                dispatch(closeModal())
              } else
                Swal.fire({
                  icon: 'error',
                  title: `#${res?.code}`,
                  text: res?.message,
                })
            })
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(false)
      }
    },
  })

  useEffect(() => {
    if (update) {
      formik.setValues(teacher)
    }
    Api()
      .categories.getCategories()
      .then(({data}) => setKeywords(data))
  }, [])
  return (
    <Modal show={modalActive} onHide={() => dispatch(closeModal())}>
      <Modal.Body>
        <ModalHeader handleClose={() => dispatch(closeModal())} titleHeader={'Eğitmen'} />
        <form id='kt_modal_add_user_form' onSubmit={formik.handleSubmit} className='form' noValidate>
          {/* begin::Scroll */}
          <div
            className='d-flex flex-column scroll-y me-n7 pe-7 '
            id='kt_modal_add_user_scroll'
            data-kt-scroll='true'
            data-kt-scroll-activate='{default: false, lg: true}'
            data-kt-scroll-max-height='auto'
            data-kt-scroll-dependencies='#kt_modal_add_user_header'
            data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
            data-kt-scroll-offset='300px'
          >
            <div className='row mb-7 d-flex'>
              <div className='col-xs-12 col-md-6'>
                <label className='required fw-bold fs-6 mb-2'>Kategori İsmi</label>
                <input
                  placeholder=''
                  {...formik.getFieldProps('name')}
                  type='text'
                  name='name'
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.name && formik.errors.name},
                    {
                      'is-valid': formik.touched.name && !formik.errors.name,
                    }
                  )}
                  autoComplete='off'
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.name?.toString()}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-xs-12 col-md-6'>
                {/* begin::Label */}
                <label className='required fw-bold fs-6 mb-2'>Kategoriler</label>
                {/* end::Label */}
                {/* begin::Input */}
                <Multiselect
                  options={keywords} // Options to display in the dropdown
                  selectedValues={selectedKeywords}
                  avoidHighlightFirstOption={true}
                  onSelect={onSelectKeyword} // Function will trigger on select event
                  onRemove={onRemoveKeyword} // Function will trigger on remove event
                  displayValue='name'
                  // onSearch={function noRefCheck() {}}
                  // showCheckbox // Property name to display in the dropdown options
                  placeholder='Seç'
                  style={{
                    chips: {
                      background: '#246F77',
                    },
                    searchBox: {},
                  }}
                />
              </div>
            </div>

            <div className='row mb-7 d-flex'>
              <div className='col-xs-12 col-md-12'>
                <label className='required fw-bold fs-6 mb-2'>Açıklama</label>
                <textarea
                  placeholder=''
                  {...formik.getFieldProps('description')}
                  name='description'
                  rows={4}
                  className={clsx(
                    'form-control form-control-solid mb-3 mb-lg-0',
                    {'is-invalid': formik.touched.description && formik.errors.description},
                    {
                      'is-valid': formik.touched.description && !formik.errors.description,
                    }
                  )}
                  autoComplete='off'
                ></textarea>
                {formik.touched.description && formik.errors.description && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.description.toString()}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {!update && (
              <>
                <div className='fv-row mb-8'>
                  <AddPhoto file={showCover} setFile={setShowCover} text='Resim Seç' key={1} />
                </div>
                <div className='fv-row mb-8'>
                  <AddPhoto file={logo} setFile={setLogo} text='Logo Seç' key={2} />
                </div>
              </>
            )}

            <div className='text-center pt-15'>
              <button onClick={() => dispatch(closeModal())} className='btn btn-light me-3' data-kt-users-modal-action='cancel'>
                İptal
              </button>

              <button
                type='submit'
                className='btn btn-primary'
                data-kt-users-modal-action='submit'
                disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
              >
                <span className='indicator-label'>Gönder</span>
                {formik.isSubmitting && (
                  <span className='indicator-progress'>
                    Lütfen Bekleyin... <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default AddTeacher
