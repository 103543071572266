import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'
import * as auth from '../../app/modules/auth'
import CategorySlice from '../../app/pages/categories/reducers/CategorySlice'
import AvatarCategorySlice from '../../app/pages/avatar-categories/reducers/CategorySlice'
import AvatarSlice from '../../app/pages/avatars/reducers/CategorySlice'
import TeacherSlice from '../../app/pages/teachers/reducers/TeacherSlice'
import ClassroomSlice from '../../app/pages/classrooms/reducers/ClassroomSlice'
import ClassroomDetailSlice from '../../app/pages/classroom-detail/reducers/ClassroomSlice'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  category: CategorySlice,
  avatar: AvatarSlice,
  avatar_categories: AvatarCategorySlice,
  teacher: TeacherSlice,
  classroom: ClassroomSlice,
  classroomDetail: ClassroomDetailSlice,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga()])
}
