import { FC } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { Api } from "../../../Services/api";
import {
  openModal,
  setRefresh,
  setUpdateFile,
} from "../reducers/CategorySlice";

type Props = {
  item: any;
  setRecord: any;
};

export const AvatarItem: FC<Props> = ({ item, setRecord }) => {
  const dispatch = useDispatch();
  function deleteItem() {
    Swal.fire({
      title: "Kayıt silinecektir emin misiniz?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Evet",
      cancelButtonText: "Vazgeç",
    }).then((result) => {
      if (result.isConfirmed) {
        Api()
          .avatars.deleteAvatar(item?.id)
          .then((res) => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: res?.message,
              showConfirmButton: false,
              timer: 1500,
            });
            dispatch(setRefresh());
          });
      }
    });
  }
  return (
    <div className="col-md-3 text-center">
      <div className="ws-item ">
        <img
          width="100%"
          height="100%"
          src={item?.file?.path}
          style={{ borderRadius: "10px" }}
        />
      </div>
      <div className="mt-4 fw-bold text-gray-800">{item?.name}</div>
      <Dropdown
        className="mb-5 mt-3"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
      >
        <Dropdown.Toggle
          variant="primary"
          id="dropdown-basic"
          style={{ width: "100%" }}
        >
          İşlem
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item>
            {" "}
            <div className="menu-item px-3">
              <div
                className="menu-link px-3"
                onClick={() => {
                  dispatch(openModal());
                  setRecord(item);
                }}
              >
                Düzenle
              </div>
            </div>
          </Dropdown.Item>
          <Dropdown.Item>
            {" "}
            <div className="menu-item px-3">
              <div
                className="menu-link px-3"
                onClick={() => {
                  dispatch(openModal());
                  setRecord(item);
                  dispatch(setUpdateFile());
                }}
              >
                Resmi Düzenle
              </div>
            </div>
          </Dropdown.Item>
          <Dropdown.Item>
            {" "}
            <div className="menu-item px-3" onClick={deleteItem}>
              <a
                href="#"
                className="menu-link px-3 text-danger"
                data-kt-ecommerce-product-filter="delete_row"
              >
                Sil
              </a>
            </div>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
