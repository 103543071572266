import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../_theme/helpers'

type Props = {
  image: string
  count: string
  text: string
}

export const CardsWidget2: FC<Props> = ({image, count, text}) => {
  return (
    <div
      className={`card card-flush h-lg-100`}
      style={{backgroundColor: '#FFFFFF', border: '1px solid #E2E8F0', boxShadow: '0px 8px 13px -3px #00000012'}}
    >
      {/* begin::Body */}
      <div className='card-body flex-column text-center'>
        {/* begin::Icon */}
        <img src={toAbsoluteUrl(image)} alt='' style={{alignItems: 'center', width: '50px', height: '50px'}} />
        {/* end::Icon */}
        {/* begin::Section */}
        <div className='d-flex flex-column my-7'>
          {/* begin::Number */}
          <span className='fw-bolder fs-2x  lh-1' style={{color: '#652EDA'}}>
            {count}
          </span>
          {/* end::Number */}
          {/* begin::Follower */}
          <span className='fw-bold fs-6 text-gray-400'>{text}</span>
          {/* end::Follower */}
        </div>
        {/* end::Section */}
      </div>
      {/* end::Body */}
    </div>
  )
}
