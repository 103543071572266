import React from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, toAbsoluteUrl} from '../../../helpers'
import {useLayout} from '../../core'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
  children?: React.ReactNode
}

const AsideMenuItem: React.FC<Props> = ({children, to, title, icon, fontIcon, hasBullet = false}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {aside} = config

  return (
    <div className='menu-item'>
      <Link className={clsx('menu-link without-sub  text-center', {active: isActive})} to={to}>
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && aside.menuIcon === 'svg' && (
          <span className='menu-icon'>
            <img src={toAbsoluteUrl(icon)} style={{width: '20px'}} alt='' />
            {/* <KTSVG path={icon} className='svg-icon svg-icon-5' /> */}
          </span>
        )}
        {/* {fontIcon && aside.menuIcon === 'svg' && (
          <span className='menu-icon '>
            <i className={clsx('fs-3 menu-icon', fontIcon)}></i>
          </span>
        )} */}
        <span className='menu-title text-uppercase d-flex justify-content-center w-100'>{title}</span>
      </Link>
      {children}
    </div>
  )
}

export {AsideMenuItem}
