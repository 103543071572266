import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {openModal} from './reducers/CategorySlice'
import {Api} from '../../Services/api'
import {useAppSelector} from '../../../setup/hooks/redux'
import {AvatarItem} from './components/AvatarItem'
import AddAvatar from './modals/AddAvatar'
import Pagination from '../components/Pagination'

const Avatars = () => {
  const dispatch = useDispatch()
  const {modalActive, refresh, updateFile} = useAppSelector((state) => state.avatar)
  const [record, setRecord] = useState<any>()
  const [avatars, setAvatars] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const [limit, setLimit] = useState(8)
  const [total, setTotal] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    Api()
      .avatars.getAvatars(currentPage, limit)
      .then((res) => {
        setTotal(res?.meta?.total)
        setAvatars(res?.data)
        setLoading(false)
      })
  }, [refresh, currentPage])
  return (
    <div>
      {modalActive && <AddAvatar avatar={record} />}

      <div className='card-header align-items-center py-5 gap-2 gap-md-5'>
        <div className='card-toolbar d-flex justify-content-end gap-5'>
          <div
            onClick={() => {
              setRecord(undefined)
              dispatch(openModal())
            }}
            className='btn btn-primary'
          >
            Ekle
          </div>
        </div>
      </div>
      <div className='card-body pt-0'>
        {loading ? (
          <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
            <div className='spinner-border' role='status'>
              <span className='visually-hidden'>Yükleniyor...</span>
            </div>
          </div>
        ) : (
          <div className='position-relative min-h-300px  scroll-x'>
            <div className='row d-flex'>
              {avatars?.map((item: any) => {
                return <AvatarItem key={item?.id} item={item} setRecord={setRecord} />
              })}
            </div>
          </div>
        )}
        <Pagination
          onPageChange={(page: number) => setCurrentPage(page)}
          totalCount={total}
          currentPage={currentPage}
          pageSize={limit}
          loading={loading}
        />
      </div>
    </div>
  )
}

export default Avatars
