import {useEffect, useState} from 'react'
import {Api} from '../../Services/api'
import {useAppSelector} from '../../../setup/hooks/redux'
import Pagination from '../components/Pagination'
import {ClassroomItem} from './components/ClassroomItem'
import AddClassroom from './modals/AddClassroom'
import {openModal} from './reducers/ClassroomSlice'
import {useDispatch} from 'react-redux'
import UpdateThumbnail from './modals/UpdateThumbnail'
import UpdateCover from './modals/UpdateCover'
import UpdateBanner from './modals/UpdateBanner'
import UpdateDocument from './modals/UpdateDocument'

const Classrooms = () => {
  const dispatch = useDispatch()
  const {modalActive, refresh, thumbnailModal, coverModal, bannerModal, documentModal} = useAppSelector((state) => state.classroom)
  const [record, setRecord] = useState<any>()
  const [classrooms, setClassrooms] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const [limit, setLimit] = useState(8)
  const [total, setTotal] = useState(1)
  const [currentPage, setCurrentPage] = useState(1)
  useEffect(() => {
    Api()
      .classromms.getClassrooms(currentPage, limit)
      .then((res) => {
        setTotal(res?.meta?.total)
        setClassrooms(res?.data)
        setLoading(false)
      })
  }, [refresh, currentPage])
  return (
    <div>
      {modalActive && <AddClassroom classroom={record} />}
      {thumbnailModal && <UpdateThumbnail classroom={record} />}
      {coverModal && <UpdateCover classroom={record} />}
      {bannerModal && <UpdateBanner classroom={record} />}
      {documentModal && <UpdateDocument classroom={record} />}

      <div className='card-header align-items-center py-5 gap-2 gap-md-5 '>
        <div className='card-toolbar d-flex justify-content-end gap-5 '>
          <div
            onClick={() => {
              dispatch(openModal())
            }}
            className='btn btn-primary'
          >
            Ekle
          </div>
        </div>
      </div>
      <div className='card-body pt-0'>
        {loading ? (
          <div className='d-flex justify-content-center mt-5' style={{height: '50px'}}>
            <div className='spinner-border' role='status'>
              <span className='visually-hidden'>Yükleniyor...</span>
            </div>
          </div>
        ) : (
          <div className='min-h-300px h-100'>
            <div className='w-100 d-flex row'>
              {classrooms?.map((item: any) => {
                return <ClassroomItem key={item?.id} item={item} setRecord={setRecord} />
              })}
            </div>
          </div>
        )}
        <Pagination
          onPageChange={(page: number) => setCurrentPage(page)}
          totalCount={total}
          currentPage={currentPage}
          pageSize={limit}
          loading={loading}
        />
      </div>
    </div>
  )
}

export default Classrooms
