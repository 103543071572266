import {createSlice} from '@reduxjs/toolkit'
import {stat} from 'fs'

interface ClassroomDetailState {
  isLoading: boolean
  error: string
  modalActive: boolean
  refresh: boolean
  update: boolean
  thumbnailModal: boolean
  bannerModal: boolean
  coverModal: boolean
  documentModal: boolean
}

const initialState: ClassroomDetailState = {
  isLoading: false,
  error: '',
  modalActive: false,
  refresh: false,
  update: false,
  thumbnailModal: false,
  bannerModal: false,
  coverModal: false,
  documentModal: false,
}

export const ClassroomDetailSlice = createSlice({
  name: 'classroomDetail',
  initialState,
  reducers: {
    openModal: (state) => {
      state.modalActive = true
    },
    closeModal: (state) => {
      state.modalActive = false
      state.update = false
    },
    setRefresh: (state) => {
      state.refresh = !state.refresh
    },
    setUpdate: (state) => {
      state.update = true
    },
    setThumbnailModal: (state) => {
      state.thumbnailModal = !state.thumbnailModal
    },
    setBannerModal: (state) => {
      state.bannerModal = !state.bannerModal
    },
    setCoverModal: (state) => {
      state.coverModal = !state.coverModal
    },
    setDocumentModal: (state) => {
      state.documentModal = !state.documentModal
    },
  },
})

export const {openModal, closeModal, setRefresh, setThumbnailModal, setBannerModal, setCoverModal, setDocumentModal, setUpdate} =
  ClassroomDetailSlice.actions

export default ClassroomDetailSlice.reducer
