import Chart from "react-apexcharts";
import { FC, useEffect, useState } from "react";
import { auto, bottom, right } from "@popperjs/core";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "../../../../setup";
import { UserModel } from "../../../modules/auth/models/UserModel";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { Api } from "../../../Services/api";
import { DateComponent } from "./DateComponent";
import { Media } from "reactstrap";

export const PieWidget = () => {
  const [widgetLabels, setWidgetLabels] = useState<any>([]);
  const [widgetData, setWidgetData] = useState<any>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [control, setControl] = useState(false);
  const loginUser: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;

  useEffect(() => {
    Api()
      .dashboard.getVisitPercantage()
      .then((data: any) => {
        prdWidget(data?.data?.visit_percentage);
        setStartDate(data?.data?.start_date);
        setEndDate(data?.data?.end_date);
        setControl(true);
      });
  }, []);

  const prdWidget = (data: any) => {
    let total = 0;
    let label = [];
    let structuredData = [];
    for (let i = 0; i < data?.length; i++) {
      total = total + data[i].count;
      label.push(
        data[i].type_description + " " + Math.floor(data[i].percentage) + "%"
      );
      structuredData.push(data[i].count);
    }
    setWidgetData(structuredData);
    setWidgetLabels(label);
    setTotalCount(total);
  };

  const chart1 = {
    labels: widgetLabels,
    dataLabels: {
      enabled: true,
      display: auto,
      color: "#223388",
      font: {
        size: 100,
      },
    },
    fill: {
      colors: [
        "#0FADCF",
        "#6577F3",
        "#652EDA",
        "#80CAEE",
        "#775dd0",
        "#98a9af",
        "#6BFFC7",
      ],
    },
    colors: [
      "#0FADCF",
      "#6577F3",
      "#652EDA",
      "#80CAEE",
      "#775dd0",
      "#98a9af",
      "#6BFFC7",
    ],
    legend: {
      show: true,
      position: bottom,
      harizontalAlign: "center",
      fontSize: "12rem",
      floating: false,
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
      formatter: function (val: any) {
        return val.toLocaleString();
      },
    },

    tooltip: {
      style: {
        fontSize: "16px",
        zIndex: 999,
      },
      y: {
        formatter: function (val: any) {
          return val.toLocaleString() + " ziyaretçi";
        },
      },
    },
  };

  const textCenterStyles: React.CSSProperties = {
    fontSize: "30px",
    position: "absolute",
    top: "125px",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    lineHeight: "20px",
  };
  return (
    <div
      className={`card h-xl-100`}
      style={{
        backgroundColor: "#FFFFFF",
        border: "1px solid #E2E8F0",
        boxShadow: "0px 8px 13px -3px #00000012",
      }}
    >
      <div className="card-body p-5 overflow-hidden">
        <div className="row">
          <div className=" text-center fs-3 fw-bolder col-xs-12 col-sm-12 col-md-5 my-1">
            Ziyaretçi Analitiği
          </div>
          <div className="col-xs-12 col-sm-12 col-md-7">
            {control && (
              <DateComponent start_date={startDate} end_date={endDate} />
            )}
          </div>
        </div>

        <div
          className="d-flex justify-content-center w-100 h-100 mt-10 "
          style={{ position: "relative" }}
        >
          <Chart
            options={chart1}
            series={widgetData}
            type="donut"
            height={"320px"}
          />
          <div style={textCenterStyles} className="mobileTextCenter">
            <span className="fw-bolder">{totalCount}</span> <br />
            <span className="fs-6 text-gray-600">Ziyaretçi</span>
          </div>
        </div>
      </div>
    </div>
  );
};
