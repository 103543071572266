import {createSlice} from '@reduxjs/toolkit'

interface TeacherState {
  isLoading: boolean
  error: string
  modalActive: boolean
  refresh: boolean
  logoModal: boolean
  photoModal: boolean
  update: boolean
}

const initialState: TeacherState = {
  isLoading: false,
  error: '',
  modalActive: false,
  refresh: false,
  logoModal: false,
  photoModal: false,
  update: false,
}

export const TeacherSlice = createSlice({
  name: 'teacher',
  initialState,
  reducers: {
    openTeacherModal: (state) => {
      state.modalActive = true
    },
    closeModal: (state) => {
      state.modalActive = false
      state.update = false
    },
    changeLogoModal: (state) => {
      state.logoModal = !state.logoModal
    },
    changePhotoModal: (state) => {
      state.photoModal = !state.photoModal
    },
    setRefresh: (state) => {
      state.refresh = !state.refresh
    },
    setUpdate: (state) => {
      state.update = true
    },
  },
})

export const {openTeacherModal, closeModal, setRefresh, changeLogoModal, changePhotoModal, setUpdate} = TeacherSlice.actions

export default TeacherSlice.reducer
