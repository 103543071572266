export const AvatarApi = (instance: any) => ({
  // avatars
  async getAvatars(pageParam: any, limit: any) {
    const {data} = await instance.get(`avatars?page[number]=${pageParam}&page[size]=${limit}`)
    return data
  },
  async addAvatar(avatar: any) {
    const {data} = await instance.post('avatars', avatar)
    return data
  },

  async deleteAvatar(avatar_id: any) {
    const {data} = await instance.delete(`avatars/${avatar_id}`)
    return data
  },
  async updateAvatar(avatar_id: any, avatar: any) {
    const {data} = await instance.put(`avatars/${avatar_id}`, avatar)
    return data
  },

  async updateAvatarFile(avatar_id: any, file: any) {
    const {data} = await instance.post(`avatars/${avatar_id}/file`, file)
    return data
  },
  // avatar-category
  async getAvatarCategories() {
    const {data} = await instance.get('avatar_categories')
    return data
  },

  async addAvatarCategory(category: any) {
    const {data} = await instance.post('avatar_categories', category)
    return data
  },

  async deleteAvatarCategory(category_id: any) {
    const {data} = await instance.delete(`avatar_categories/${category_id}`)
    return data
  },

  async updateAvatarCategory(category_id: any, category: any) {
    const {data} = await instance.put(`avatar_categories/${category_id}`, category)
    return data
  },
})
