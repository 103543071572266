import {FC, useState} from 'react'
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap'
import {useDispatch} from 'react-redux'
import Swal from 'sweetalert2'
import {Api} from '../../../Services/api'
import '../style.css'
import {toAbsoluteUrl} from '../../../../_theme/helpers'
import {changeLogoModal, changePhotoModal, openTeacherModal, setRefresh, setUpdate} from '../reducers/TeacherSlice'

type Props = {
  item: any
  setRecord: any
}

export const TeacherItem: FC<Props> = ({item, setRecord}) => {
  const dispatch = useDispatch()
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggle = () => setDropdownOpen((prevState) => !prevState)
  function deleteItem() {
    Swal.fire({
      title: 'Kayıt silinecektir emin misiniz?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Evet',
      cancelButtonText: 'Vazgeç',
    }).then((result) => {
      if (result.isConfirmed) {
        Api()
          .classromms.deleteClassroom(item?.slug)
          .then((res) => {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: res?.message,
              showConfirmButton: false,
              timer: 1500,
            })
            dispatch(setRefresh())
          })
      }
    })
  }
  return (
    <div className='col-6 col-md-3  text-center mb-5 h-auto'>
      <div
        className='ws-card'
        style={{
          background: `url(${item?.photo?.path})`,
        }}
      >
        <Dropdown className='ws-card-menu-icon' isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle className='cursor-pointer' tag='div'>
            {' '}
            <img src={toAbsoluteUrl('/media/icons/menu/icons-more-vertical.png')} alt='' />
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem>
              {' '}
              <div className='menu-item px-3'>
                <div
                  className='menu-link px-3'
                  onClick={() => {
                    dispatch(openTeacherModal())
                    dispatch(setUpdate())
                    setRecord(item)
                  }}
                >
                  Düzenle
                </div>
              </div>
            </DropdownItem>
            <DropdownItem>
              {' '}
              <div className='menu-item px-3'>
                <div
                  className='menu-link px-3'
                  onClick={() => {
                    dispatch(changePhotoModal())
                    setRecord(item)
                  }}
                >
                  Resmi Değiştir
                </div>
              </div>
            </DropdownItem>
            <DropdownItem>
              {' '}
              <div className='menu-item px-3'>
                <div
                  className='menu-link px-3'
                  onClick={() => {
                    dispatch(changeLogoModal())
                    setRecord(item)
                  }}
                >
                  Logo'yu Değiştir
                </div>
              </div>
            </DropdownItem>
            <DropdownItem>
              {' '}
              <div className='menu-item px-3' onClick={deleteItem}>
                <a href='#' className='menu-link px-3 text-danger' data-kt-ecommerce-product-filter='delete_row'>
                  Sil
                </a>
              </div>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <div className='ws-card-img-bg'></div>
        <div className='d-flex flex-column justify-content-between align-items-center w-100 ws-title'>
          <div className='text-center d-flex align-items-center justify-content-end flex-column '>
            <div className='ws-card-title'>
              {item?.logo ? (
                <img src={item?.logo?.path} alt={`${item?.name} logosu`} style={{width: '70%'}} />
              ) : (
                <h3 style={{textTransform: 'uppercase'}}>{item?.teacher?.name}</h3>
              )}
            </div>
            <div className='mt-auto'>
              <div className='ws-card-line'></div>
              <div style={{textTransform: 'capitalize'}} className='d-flex justify-content-center text-center w-100 px-3 mb-1 text-white'>
                {item?.categories[0]?.name}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
