import React, { FC, useState } from "react";
import Dropzone from "react-dropzone";

type Props = {
  file: any;
  setFile: any;
  text: string;
};
export const AddPhoto: FC<Props> = ({ file, setFile, text }) => {
  const [errorsCover, setErrorsCover] = useState("");
  const removePhoto = (f: any) => () => {
    const newFiles = [...file];
    newFiles.splice(newFiles.indexOf(f), 1);
    setFile(newFiles);
  };
  const showPhoto = file?.map(
    (
      file: {
        name: string;
        path: {} | null | undefined;
        size:
          | boolean
          | React.ReactChild
          | React.ReactFragment
          | React.ReactPortal
          | null
          | undefined;
      },
      index: React.Key | null | undefined
    ) => (
      <div key={index} className="row">
        <div className="col-10 h-auto overflow-auto">
          <>
            {file.name} - {file.size} bytes
          </>{" "}
        </div>
        <div className="col-2">
          <span
            className="btn btn-primary mr-10"
            style={{ color: "white" }}
            onClick={removePhoto(file)}
          >
            Sil
          </span>
        </div>
      </div>
    )
  );
  return (
    <div>
      {/* <label className='fs-7 fw-bold mb-2'>Cover Resim Seç</label> */}

      <div style={{ display: "block" }} className="fv-row mb-8">
        <div className="dropzone" id="kt_modal_create_ticket_attachments">
          <div className="dz-message needsclick align-items-center">
            <span
              className="svg-icon svg-icon-3hx svg-icon-primary"
              style={{ color: "rgb(1 13 88)" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  opacity="0.3"
                  d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM14.5 12L12.7 9.3C12.3 8.9 11.7 8.9 11.3 9.3L10 12H11.5V17C11.5 17.6 11.4 18 12 18C12.6 18 12.5 17.6 12.5 17V12H14.5Z"
                  fill="currentColor"
                />
                <path
                  d="M13 11.5V17.9355C13 18.2742 12.6 19 12 19C11.4 19 11 18.2742 11 17.9355V11.5H13Z"
                  fill="currentColor"
                />
                <path
                  d="M8.2575 11.4411C7.82942 11.8015 8.08434 12.5 8.64398 12.5H15.356C15.9157 12.5 16.1706 11.8015 15.7425 11.4411L12.4375 8.65789C12.1875 8.44737 11.8125 8.44737 11.5625 8.65789L8.2575 11.4411Z"
                  fill="currentColor"
                />
                <path
                  d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z"
                  fill="currentColor"
                />
              </svg>
            </span>
            {/* <input type='file' onChange={handleChange} />
                <input type='file' onChange={handleChange2} multiple /> */}

            <Dropzone
              accept={{ "image/*": [".png", ".jpg", ".jpeg", ".pdf"] }}
              multiple={false}
              maxFiles={1}
              onDrop={async (acceptedFiles, fileRejections) => {
                setFile(acceptedFiles);
                fileRejections.forEach((file) => {
                  file.errors.forEach((err) => {
                    if (err.code === "file-invalid-type") {
                      setErrorsCover(`Hata: ${err.message}`);
                    } else {
                      setErrorsCover(`Hata: ${err.message}`);
                    }
                  });
                });
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="ms-4">
                      <h3 className="fs-5 fw-bolder text-gray-900 mb-1">
                        {text}
                      </h3>
                      <span className="fw-bold fs-7 text-gray-400">
                        1 adet resim
                      </span>
                    </div>
                    <p
                      style={{
                        color: "red",
                        padding: 5,
                        margin: 0,
                        fontSize: 14,
                      }}
                    >
                      {}
                    </p>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>
        </div>
      </div>
      <aside>
        <ul className="m-0 p-0">{showPhoto}</ul>
      </aside>
    </div>
  );
};
