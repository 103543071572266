import {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {openModal} from './reducers/CategorySlice'
import AddCategory from './modals/AddCategory'
import {Api} from '../../Services/api'
import CategoryItem from './components/CategoryItem'
import {useAppSelector} from '../../../setup/hooks/redux'

const AvatarCategories = () => {
  const dispatch = useDispatch()
  const {modalActive, refresh} = useAppSelector((state) => state.avatar_categories)
  const [record, setRecord] = useState<any>()
  const [categories, setCategories] = useState<any>([])
  useEffect(() => {
    Api()
      .avatars.getAvatarCategories()
      .then(({data}) => setCategories(data))
  }, [refresh])
  return (
    <div>
      {modalActive && <AddCategory category={record} />}
      <div className='card card-flush'>
        <div className='card-header align-items-center py-5 gap-2 gap-md-5'>
          <div className='card-toolbar flex-row-fluid justify-content-end gap-5'>
            <div
              onClick={() => {
                setRecord(undefined)
                dispatch(openModal())
              }}
              className='btn btn-primary'
            >
              Ekle
            </div>
          </div>
        </div>
        <div className='card-body pt-0'>
          <div className='position-relative min-h-300px  scroll-x'>
            <table className='table align-middle table-row-dashed fs-6 gy-5' id='kt_ecommerce_products_table'>
              <thead>
                <tr className='text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0'>
                  <th className='min-w-200px'>Kategori</th>
                  <th className='text-end min-w-70px'>İşlem</th>
                </tr>
              </thead>
              <tbody className='fw-bold text-gray-600'>
                {categories?.map((item: any) => {
                  return <CategoryItem key={item?.id} item={item} setRecord={setRecord} />
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AvatarCategories
