import { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import { ModalHeader } from "../../../Modals/ModalHeader";
import clsx from "clsx";
import { useAppSelector } from "../../../../setup/hooks/redux";
import { useDispatch } from "react-redux";
import Dropzone from "react-dropzone";
import { Api } from "../../../Services/api";
import Swal from "sweetalert2";
import { closeModal, setRefresh } from "../reducers/ClassroomSlice";
import { AddPhoto } from "../../components/AddPhoto";

type Props = {
  classroom: any;
};

const ClassroomSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "En az 3 karakter olmalı!")
    .required("Zorunlu alan"),
  description: Yup.string().min(8).required("Zorunlu alan"),
  //   category_id: Yup.number().required('Zorunlu alan'),
  //   teacher_id: Yup.string().required('Zorunlu alan'),
  raw_files_path: Yup.string(),
  //   coming_soon: Yup.string().required('Zorunlu alan'),
  //   specs: Yup.string().required('Zorunlu alan'),
});
const UpdateClassroomSchema = Yup.object().shape({});

const AddAvatar: FC<Props> = ({ classroom }) => {
  // states
  const [verticalPhoto, setVerticalPhoto] = useState<any>([]);
  const [horizontalPhoto, setHorizontalPhoto] = useState<any>([]);
  const [headerPhoto, setHeaderPhoto] = useState<any>([]);
  const [document, setDocument] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [teachers, setTeachers] = useState<any>([]);
  const [videos, setVideos] = useState<any>([]);
  const [selectedVideo, setSelectedVideo] = useState(
    classroom?.raw_teaser_path
  );
  const [selectedCategory, setSelectedCategory] = useState(
    classroom?.category?.id
  );
  const [selectedTeacher, setSelectedTeacher] = useState(
    classroom?.teacher?.id
  );
  const [isComingSoon, setIsComingSoon] = useState(classroom?.coming_soon);
  const [specs, setSpecs] = useState(classroom?.specs);
  const { modalActive, update } = useAppSelector((state) => state.classroom);
  const dispatch = useDispatch();
  const [categoryForEdit] = useState<any>({
    name: "",
  });
  let formData = new FormData();
  const formik = useFormik({
    initialValues: categoryForEdit,
    validationSchema: classroom ? UpdateClassroomSchema : ClassroomSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      formData.append("name", values.name);
      formData.append("raw_files_path", values.raw_files_path);
      formData.append("description", values.description);
      formData.append("category_id", selectedCategory);
      formData.append("teacher_id", selectedTeacher);
      formData.append("specs", specs);
      formData.append("coming_soon", isComingSoon);
      formData.append("thumbnail", verticalPhoto[0]);
      formData.append("cover", horizontalPhoto[0]);
      formData.append("banner", headerPhoto[0]);
      formData.append("document", document[0]);

      try {
        if (update) {
          const body = {
            name: values.name,
            raw_files_path: values.raw_files_path,
            description: values.description,
            category_id: selectedCategory,
            teacher_id: selectedTeacher,
            specs: specs,
            coming_soon: isComingSoon,
            teaser: selectedVideo,
          };

          Api()
            .classromms.updateClassroom(classroom?.slug, body)
            .then((res) => {
              if (res?.data?.success) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: res?.data?.message,
                  showConfirmButton: false,
                  timer: 1500,
                });
                dispatch(setRefresh());
                dispatch(closeModal());
              } else {
                Swal.fire({
                  icon: "error",
                  title: `#${res?.status}`,
                  text: res?.statusText,
                });
              }
            });
        } else {
          Api()
            .classromms.addClassroom(formData)
            .then((res) => {
              if (res?.data?.success) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: res?.data?.message,
                  showConfirmButton: false,
                  timer: 1500,
                });
                dispatch(setRefresh());
                dispatch(closeModal());
              } else {
                Swal.fire({
                  icon: "error",
                  title: `#${res?.status}`,
                  text: res?.statusText,
                });
              }
            });
        }
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (update) {
      formik.setValues(classroom);
      Api()
        .classromms.getLessonVideos(classroom.slug)
        .then(({ data }) => {
          setVideos(data?.data);
        });
    }

    Api()
      .categories.getCategories()
      .then(({ data }) => setCategories(data));
    Api()
      .teachers.getTeachers("")
      .then(({ data }) => setTeachers(data));
  }, []);
  return (
    <Modal show={modalActive} onHide={() => dispatch(closeModal())}>
      <Modal.Body>
        <ModalHeader
          handleClose={() => dispatch(closeModal())}
          titleHeader={"Eğitim"}
        />
        <form
          id="kt_modal_add_user_form"
          onSubmit={formik.handleSubmit}
          className="form"
          noValidate
        >
          {/* begin::Scroll */}
          <div
            className="d-flex flex-column scroll-y me-n7 pe-7 "
            id="kt_modal_add_user_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_add_user_header"
            data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
            data-kt-scroll-offset="300px"
          >
            <div className="row mb-7 d-flex">
              <div className="col-xs-12 col-md-6">
                <label className="required fw-bold fs-6 mb-2">Başlık</label>

                <input
                  placeholder=""
                  {...formik.getFieldProps("name")}
                  type="text"
                  name="name"
                  className={clsx(
                    "form-control form-control-solid mb-3 mb-lg-0",
                    { "is-invalid": formik.touched.name && formik.errors.name },
                    {
                      "is-valid": formik.touched.name && !formik.errors.name,
                    }
                  )}
                  autoComplete="off"
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.name?.toString()}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-xs-12 col-md-6">
                <label className="required fw-bold fs-6 mb-2">Path</label>

                <input
                  placeholder=""
                  {...formik.getFieldProps("raw_files_path")}
                  type="text"
                  name="raw_files_path"
                  className={clsx(
                    "form-control form-control-solid mb-3 mb-lg-0",
                    {
                      "is-invalid":
                        formik.touched.raw_files_path &&
                        formik.errors.raw_files_path,
                    },
                    {
                      "is-valid":
                        formik.touched.raw_files_path &&
                        !formik.errors.raw_files_path,
                    }
                  )}
                  autoComplete="off"
                />
                {formik.touched.raw_files_path &&
                  formik.errors.raw_files_path && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">
                          {formik.errors.raw_files_path?.toString()}
                        </span>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div className="row mb-7 d-flex">
              <div className="col-xs-12 col-md-6  mb-7">
                <label className="required fw-bold fs-6 mb-2">Kategori</label>
                <select
                  className="form-select form-select-solid"
                  data-control="select2"
                  data-hide-search="true"
                  name="category_id"
                  onChange={(e) => setSelectedCategory(e?.target?.value)}
                  defaultValue={selectedCategory}
                  value={selectedCategory}
                >
                  <option disabled hidden>
                    Seçiniz
                  </option>

                  {categories?.map((item: any) => {
                    return (
                      <option key={item?.id} value={item?.id}>
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
                {formik.touched.category_id &&
                  formik.errors.category_id &&
                  (typeof formik.errors.category_id === "string" ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.category_id}</span>
                      </div>
                    </div>
                  ) : null)}
              </div>
              <div className="col-xs-12 col-md-6  mb-7">
                <label className="required fw-bold fs-6 mb-2">Öğretmen</label>
                <select
                  className="form-select form-select-solid"
                  data-control="select2"
                  data-hide-search="true"
                  name="teacher_id"
                  onChange={(e) => setSelectedTeacher(e?.target?.value)}
                  defaultValue={selectedTeacher}
                  value={selectedTeacher}
                >
                  <option disabled hidden>
                    Seçiniz
                  </option>

                  {teachers?.map((item: any) => {
                    return (
                      <option key={item?.id} value={item?.id}>
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
                {formik.touched.teacher_id &&
                  formik.errors.teacher_id &&
                  (typeof formik.errors.teacher_id === "string" ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.teacher_id}</span>
                      </div>
                    </div>
                  ) : null)}
              </div>
            </div>
            <div className="row mb-7 d-flex">
              <div className="col-xs-12 col-md-12">
                <label className="required fw-bold fs-6 mb-2">Açıklama</label>
                <textarea
                  placeholder=""
                  {...formik.getFieldProps("description")}
                  name="description"
                  rows={4}
                  className={clsx(
                    "form-control form-control-solid mb-3 mb-lg-0",
                    {
                      "is-invalid":
                        formik.touched.description && formik.errors.description,
                    },
                    {
                      "is-valid":
                        formik.touched.description &&
                        !formik.errors.description,
                    }
                  )}
                  autoComplete="off"
                ></textarea>
                {formik.touched.description && formik.errors.description && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">
                        {formik.errors.description.toString()}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {!update && (
              <>
                <div className="fv-row mb-8">
                  <AddPhoto
                    file={verticalPhoto}
                    setFile={setVerticalPhoto}
                    text="Dikey Resim Seç"
                    key={1}
                  />
                </div>
                <div className="fv-row mb-8">
                  <AddPhoto
                    file={horizontalPhoto}
                    setFile={setHorizontalPhoto}
                    text="Yatay Resim Seç"
                    key={2}
                  />
                </div>
                <div className="fv-row mb-8">
                  <AddPhoto
                    file={headerPhoto}
                    setFile={setHeaderPhoto}
                    text="Header Resmini Seç"
                    key={3}
                  />
                </div>
                <div className="fv-row mb-8">
                  <AddPhoto
                    file={document}
                    setFile={setDocument}
                    text="Belge Seç"
                    key={4}
                  />
                </div>
              </>
            )}
            <div className="row mb-7 d-flex">
              <div className="col-xs-12 col-md-6  mb-7">
                <label className="required fw-bold fs-6 mb-2">
                  Yakında Geleceklere Ekle
                </label>
                <select
                  className="form-select form-select-solid"
                  data-control="select2"
                  data-hide-search="true"
                  name="coming_soon"
                  onChange={(e) => setIsComingSoon(e?.target?.value)}
                  defaultValue={isComingSoon}
                  value={isComingSoon}
                >
                  <option disabled hidden selected>
                    Seçiniz
                  </option>
                  <option value={"0"}>Hayır</option>
                  <option value={"1"}>Evet</option>
                </select>
                {formik.touched.coming_soon &&
                  formik.errors.coming_soon &&
                  (typeof formik.errors.coming_soon === "string" ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.coming_soon}</span>
                      </div>
                    </div>
                  ) : null)}
              </div>
              <div className="col-xs-12 col-md-6  mb-7">
                <label className="required fw-bold fs-6 mb-2">Yayınla</label>
                <select
                  className="form-select form-select-solid"
                  data-control="select2"
                  data-hide-search="true"
                  name="specs"
                  onChange={(e) => setSpecs(e?.target?.value)}
                  defaultValue={specs}
                  value={specs}
                >
                  <option disabled hidden selected>
                    Seçiniz
                  </option>
                  <option value={"0"}>Hayır</option>
                  <option value={"1"}>Evet</option>
                </select>
                {formik.touched.specs &&
                  formik.errors.specs &&
                  (typeof formik.errors.specs === "string" ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.specs}</span>
                      </div>
                    </div>
                  ) : null)}
              </div>
            </div>
            {update && (
              <div className="row mb-7 d-flex">
                <div className="col-xs-12 col-md-12  mb-7">
                  <label className="required fw-bold fs-6 mb-2">
                    Giriş Videosu Seç
                  </label>
                  <select
                    className="form-select form-select-solid"
                    data-control="select2"
                    data-hide-search="true"
                    name="teaser"
                    onChange={(e) => setSelectedVideo(e?.target?.value)}
                    defaultValue={selectedVideo}
                    value={selectedVideo}
                  >
                    <option disabled hidden>
                      Seçiniz
                    </option>

                    {videos?.map((item: any, index: any) => {
                      return (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                  {formik.touched.teaser &&
                    formik.errors.teaser &&
                    (typeof formik.errors.teaser === "string" ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert">{formik.errors.teaser}</span>
                        </div>
                      </div>
                    ) : null)}
                </div>
              </div>
            )}

            <div className="text-center pt-15">
              <button
                onClick={() => dispatch(closeModal())}
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
              >
                İptal
              </button>

              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={
                  formik.isSubmitting || !formik.isValid || !formik.touched
                }
              >
                <span className="indicator-label">Gönder</span>
                {formik.isSubmitting && (
                  <span className="indicator-progress">
                    Lütfen Bekleyin...{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default AddAvatar;
