import { Navigate, Route, Routes } from "react-router-dom";
import { MasterLayout } from "../../_theme/layout/MasterLayout";
import { PageLink, PageTitle } from "../../_theme/layout/core";
import { useSelector, shallowEqual } from "react-redux";
import { RootState } from "../../setup";
import { UserModel } from "../modules/auth/models/UserModel";
import AvatarCategories from "../pages/avatar-categories/AvatarCategories";
import Avatars from "../pages/avatars/Avatars";
import Teachers from "../pages/teachers/Teachers";
import Categories from "../pages/categories/Categories";
import Classrooms from "../pages/classrooms/Classrooms";
import { Dashboard } from "../pages/dashboard/Dashboard";
import { ClassroomDetail } from "../pages/classroom-detail/ClassroomDetail";

const PrivateRoutes = () => {
  const loginUser: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;

  const dashboardBreadCrumbs: Array<PageLink> = [
    {
      title: "Anasayfa",
      path: "/dashboard",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registration */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        <Route
          path="dashboard"
          element={
            <>
              <PageTitle>{"Merhaba, " + loginUser?.name}</PageTitle>
              <Dashboard />
            </>
          }
        ></Route>
        <Route
          path="/"
          element={
            <>
              <PageTitle>{"Merhaba, " + loginUser?.name}</PageTitle>
              <Dashboard />
            </>
          }
        ></Route>
        <Route
          path="teachers"
          element={
            <>
              <PageTitle>Eğitmenler</PageTitle>
              <Teachers />
            </>
          }
        ></Route>
        <Route
          path="classrooms/:id"
          element={
            <>
              <PageTitle>Eğitim Detayı</PageTitle>
              <ClassroomDetail />
            </>
          }
        ></Route>
        <Route
          path="classrooms"
          element={
            <>
              <PageTitle>Eğitimler</PageTitle>
              <Classrooms />
            </>
          }
        ></Route>

        <Route
          path="categories"
          element={
            <>
              <PageTitle>Kategoriler</PageTitle>
              <Categories />
            </>
          }
        ></Route>
        <Route
          path="avatars"
          element={
            <>
              <PageTitle>Avatarlar</PageTitle>
              <Avatars />
            </>
          }
        ></Route>
        <Route
          path="avatar-categories"
          element={
            <>
              <PageTitle>Avatar Kategorileri</PageTitle>
              <AvatarCategories />
            </>
          }
        ></Route>

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

export { PrivateRoutes };
