import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../_theme/helpers'

type Props = {
  start_date: any
  end_date: any
}
export const DateComponent: FC<Props> = ({start_date, end_date}) => {
  // Tarih nesnesine dönüştür
  // Tarih nesnesini istediğimiz formata dönüştür

  const startDate = new Date(start_date)
  const endDate = new Date(end_date)
  const startformattedDate = new Intl.DateTimeFormat('tr-TR', {day: 'numeric', month: 'long', year: 'numeric'}).format(startDate)

  // Tarih nesnesini istediğimiz formata dönüştür
  const endformattedDate = new Intl.DateTimeFormat('tr-TR', {day: 'numeric', month: 'long', year: 'numeric'}).format(endDate)
  return (
    <div
      className='card h-sm-100 h-md-75'
      style={{backgroundColor: '#FFFFFF', border: '1px solid #E2E8F0', boxShadow: '0px 8px 13px -3px #00000012'}}
    >
      <div className='d-flex justify-content-center mx-3 py-2 pb-md-5'>
        <div className='me-3  '>
          <img src={toAbsoluteUrl('/media/dashboard/images/Vector.svg')} />
        </div>
        <div className='py-1  text-gray-800'>
          {startformattedDate}-{endformattedDate}
        </div>
      </div>
    </div>
  )
}
