import { useEffect, useState } from "react";
import { Api } from "../../Services/api";
import { useParams } from "react-router-dom";
import { DetailItem } from "./components/DetailItem";
import AddLesson from "./modals/AddLesson";
import { useAppSelector } from "../../../setup/hooks/redux";
import { useDispatch } from "react-redux";
import { openModal } from "./reducers/ClassroomSlice";
import { AboutLesson } from "./components/HeaderComponent";
import UpdateThumbnail from "./modals/UpdateThumbnail";

export const ClassroomDetail = () => {
  const dispatch = useDispatch();
  const { refresh } = useAppSelector((state) => state.classroomDetail);
  const { thumbnailModal } = useAppSelector((state) => state.classroomDetail);
  const [classroom, setClassroom] = useState<any>();
  const [record, setRecord] = useState<any>();
  const { modalActive } = useAppSelector((state) => state.classroomDetail);

  const { id } = useParams();
  useEffect(() => {
    Api()
      .classromms.getClassroom(id)
      .then(({ data }) => {
        setClassroom(data);
      });
  }, [refresh]);
  return (
    <div>
      {" "}
      {modalActive && (
        <AddLesson
          classroom={record}
          classroom_slug={classroom?.slug}
          classroom_id={classroom?.id}
        />
      )}
      {thumbnailModal && <UpdateThumbnail videoInfo={record} />}
      <AboutLesson item={classroom} />
      <div className="card-header align-items-center py-5 gap-2 gap-md-5">
        <div className="card-toolbar d-flex justify-content-end gap-5">
          <div
            onClick={() => {
              setRecord(undefined);
              dispatch(openModal());
            }}
            className="btn btn-primary"
          >
            Ekle
          </div>
        </div>
      </div>
      <div className="card-body pt-0">
        <div className="position-relative min-h-300px  scroll-x mt-5">
          {classroom?.videos?.map((item: any, index: number) => {
            return (
              <DetailItem
                key={item?.id}
                item={item}
                setRecord={setRecord}
                index={index + 1}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};
