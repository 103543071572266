import axios from 'axios'
import {CategoryApi} from './categories'
import store from '../../setup/redux/Store'
import {AvatarApi} from './avatars'
import {TeacherApi} from './teachers'
import {ClassroomApi} from './classrooms'
import {DashboardApi} from './dashboards'

export const Api = () => {
  const {
    auth: {accessToken},
  } = store.getState()
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return {
    categories: CategoryApi(instance),
    avatars: AvatarApi(instance),
    teachers: TeacherApi(instance),
    classromms: ClassroomApi(instance),
    dashboard: DashboardApi(instance),
  }
}
