import {createSlice} from '@reduxjs/toolkit'
import {stat} from 'fs'

interface CategoryState {
  isLoading: boolean
  error: string
  modalActive: boolean
  refresh: boolean
}

const initialState: CategoryState = {
  isLoading: false,
  error: '',
  modalActive: false,
  refresh: false,
}

export const AvatarCategorySlice = createSlice({
  name: 'avatar_category',
  initialState,
  reducers: {
    openModal: (state) => {
      state.modalActive = true
    },
    closeModal: (state) => {
      state.modalActive = false
    },
    setRefresh: (state) => {
      state.refresh = !state.refresh
    },
  },
})

export const {openModal, closeModal, setRefresh} = AvatarCategorySlice.actions

export default AvatarCategorySlice.reducer
