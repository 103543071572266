export const ClassroomApi = (instance: any) => ({
  async getClassrooms(currentPage: any, limit: any) {
    const { data } = await instance.get(
      `classrooms/all?page[number]=${currentPage}&page[size]=${limit}`
    );
    return data;
  },
  async getClassroom(classroom_id: any) {
    const { data } = await instance.get(`classrooms/${classroom_id}`);
    return data;
  },
  async addClassroom(classroom: any) {
    const data = await instance.post("classrooms", classroom);
    return data;
  },
  async deleteClassroom(classroom_id: any) {
    const data = instance.delete(`classrooms/${classroom_id}`);
    return data;
  },
  async updateClassroom(classroom_id: any, classroom: any) {
    const data = instance.put(`classrooms/${classroom_id}`, classroom);
    return data;
  },
  async updateThumbnail(classroom_id: any, file: any) {
    const data = instance.post(`classrooms/${classroom_id}/thumbnail`, file);
    return data;
  },
  async updateCover(classroom_id: any, file: any) {
    const data = instance.post(`classrooms/${classroom_id}/cover`, file);
    return data;
  },
  async updateBanner(classroom_id: any, file: any) {
    const data = instance.post(`classrooms/${classroom_id}/banner`, file);
    return data;
  },
  async updateDocument(classroom_id: any, file: any) {
    const data = instance.post(`classrooms/${classroom_id}/document`, file);
    return data;
  },

  async getLessonVideos(classroom_id: any) {
    const data = instance.get(`classrooms/${classroom_id}/raw_files`);
    return data;
  },
  async addVideo(file: any) {
    const data = instance.post("videos", file);
    return data;
  },
  async updateVideo(video_id: string, file: any) {
    const data = instance.put(`videos/${video_id}`, file);
    return data;
  },
  async deleteVideo(video_slug: any) {
    const data = instance.delete(`videos/${video_slug}`);
    return data;
  },
  async updateViewThumbnail(video_slug: any, file: any) {
    const data = instance.post(`videos/${video_slug}/thumbnail`, file);
    return data;
  },
});
