/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { useAppSelector } from "../../../../setup/hooks/redux";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../helpers";

const AsideUser: React.FC<{ children?: React.ReactNode }> = () => {
  const { user } = useAppSelector((state) => state.auth);
  return (
    <div style={{ height: "200px" }} className="py-5">
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className=" text-center" style={{ borderRadius: "50%" }}>
          <div
            className="symbol symbol-40px cursor-pointer bg-primary"
            style={{ borderRadius: "50% " }}
          >
            {user?.email === "gurhan@ecodation.com" ? (
              <img
                src={toAbsoluteUrl("/media/avatars/huseyin_avatar.jpeg")}
                style={{
                  height: "110px",
                  width: "110px",
                  borderRadius: "20% !important",
                }}
                className="rounded-circle"
              />
            ) : (
              <div
                className="symbol-label text-inverse-primary fw-bolder fs-1 h-100px w-100px rounded-circle"
                style={{ backgroundColor: "#565674" }}
              >
                {user?.name?.substring(0, 1).toUpperCase()}
                {user?.name.substring(1, 2).toUpperCase()}
              </div>
            )}
            {/* )} */}
          </div>
          {/* <HeaderUserMenu /> */}
        </div>
        <div className="pt-2">
          <div className="text-center align-items-center">
            <div className=" text-white fw-bolder fs-3">{user?.name}</div>
          </div>
        </div>
        <div className=" ">
          <span className="text-white rounded  px-4  py-2 fs-8">
            {" "}
            {user?.roles[0]?.name === "superadmin"
              ? "Super Admin"
              : user?.roles[0]?.name}
          </span>
        </div>
      </div>
    </div>
  );
};

export default AsideUser;
