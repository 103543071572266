/* eslint-disable jsx-a11y/anchor-is-valid */
import { toAbsoluteUrl } from "../../../../_theme/helpers";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openModal } from "../../classrooms/reducers/ClassroomSlice";
import { openTeacherModal } from "../../teachers/reducers/TeacherSlice";

export const EngageWidget1 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const addClassroom = () => {
    navigate("/classrooms");
    dispatch(openModal());
  };

  const addTeacher = () => {
    navigate("/teachers");
    dispatch(openTeacherModal());
  };

  return (
    <>
      <div
        className={`card h-xl-100`}
        style={{
          backgroundColor: "#FFFFFF",
          border: "1px solid #E2E8F0",
          boxShadow: "0px 8px 13px -3px #00000012",
        }}
      >
        <div className="card-body d-flex flex-column flex-center">
          <div className="mb-2">
            <h1 className="text-gray-800 text-left fs-2">
              Yeni Eğitim <br /> eklemek ister misiniz?{" "}
            </h1>

            <img
              src={toAbsoluteUrl("/media/dashboard/images/Videos.png")}
              className="my-4 h-200px"
            />
          </div>

          <div className="text-left">
            <div className="btn btn-lg btn-primary me-2" onClick={addClassroom}>
              Eğitim Ekle
            </div>
            <div className="btn btn-lg btn-primary" onClick={addTeacher}>
              Eğitmen Ekle
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
