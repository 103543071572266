import {FC} from 'react'
import {useDispatch} from 'react-redux'
import {openModal, setRefresh} from '../reducers/CategorySlice'
import {Dropdown} from 'react-bootstrap'
import Swal from 'sweetalert2'
import {Api} from '../../../Services/api'

type Props = {
  item: any
  setRecord: any
}
const CategoryItem: FC<Props> = ({item, setRecord}) => {
  const dispatch = useDispatch()
  function deleteItem() {
    Swal.fire({
      title: 'Kayıt silinecektir emin misiniz?',
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'Evet',
      cancelButtonText: 'Vazgeç',
    }).then((result) => {
      if (result.isConfirmed) {
        Api()
          .avatars.deleteAvatarCategory(item?.id)
          .then((res) => {
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: res?.message,
              showConfirmButton: false,
              timer: 1500,
            })
            dispatch(setRefresh())
          })
      }
    })
  }
  return (
    <>
      <tr>
        <td>
          <div className='d-flex align-items-center'>
            <div className='text-gray-700 fw-bolder'>{item?.name}</div>
          </div>
        </td>

        <td className='text-end'>
          <Dropdown className='' data-kt-menu-trigger='click' data-kt-menu-placement='bottom-end'>
            <Dropdown.Toggle variant='light' id='dropdown-basic'>
              İşlem
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                {' '}
                <div className='menu-item px-3'>
                  <div
                    className='menu-link px-3'
                    onClick={() => {
                      dispatch(openModal())
                      setRecord(item)
                    }}
                  >
                    Düzenle
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item>
                {' '}
                <div className='menu-item px-3' onClick={deleteItem}>
                  <a href='#' className='menu-link px-3 text-danger' data-kt-ecommerce-product-filter='delete_row'>
                    Sil
                  </a>
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </td>
      </tr>
    </>
  )
}

export default CategoryItem
