import { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Modal } from "react-bootstrap";
import { ModalHeader } from "../../../Modals/ModalHeader";
import { useAppSelector } from "../../../../setup/hooks/redux";
import { useDispatch } from "react-redux";
import { Api } from "../../../Services/api";
import Swal from "sweetalert2";
import { setBannerModal, setRefresh } from "../reducers/ClassroomSlice";
import { AddPhoto } from "../../components/AddPhoto";

type Props = {
  classroom: any;
};

const ClassroomSchema = Yup.object().shape({});

const UpdateBanner: FC<Props> = ({ classroom }) => {
  // states
  const [verticalPhoto, setVerticalPhoto] = useState<any>([]);
  const { bannerModal } = useAppSelector((state) => state.classroom);
  const dispatch = useDispatch();
  const [categoryForEdit] = useState<any>({
    name: "",
  });
  let formData = new FormData();
  const formik = useFormik({
    initialValues: categoryForEdit,
    validationSchema: ClassroomSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      formData.append("banner", verticalPhoto[0]);
      try {
        Api()
          .classromms.updateBanner(classroom?.slug, formData)
          .then((res) => {
            if (res?.data?.success) {
              Swal.fire({
                position: "center",
                icon: "success",
                title: res?.data?.message,
                showConfirmButton: false,
                timer: 1500,
              });
              dispatch(setRefresh());
              dispatch(setBannerModal());
            } else {
              Swal.fire({
                icon: "error",
                title: `#${res?.status}`,
                text: res?.statusText,
              });
            }
          });
      } catch (ex) {
        console.error(ex);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Modal show={bannerModal} onHide={() => dispatch(setBannerModal())}>
      <Modal.Body>
        <ModalHeader
          handleClose={() => dispatch(setBannerModal())}
          titleHeader={"Eğitim"}
        />
        <form
          id="kt_modal_add_user_form"
          onSubmit={formik.handleSubmit}
          className="form"
          noValidate
        >
          {/* begin::Scroll */}
          <div
            className="d-flex flex-column scroll-y me-n7 pe-7 "
            id="kt_modal_add_user_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_add_user_header"
            data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
            data-kt-scroll-offset="300px"
          >
            <div className="fv-row mb-8">
              <AddPhoto
                file={verticalPhoto}
                setFile={setVerticalPhoto}
                text="Header Resmini Seç"
                key={1}
              />
            </div>

            <div className="text-center pt-15">
              <button
                onClick={() => dispatch(setBannerModal())}
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
              >
                İptal
              </button>

              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={
                  formik.isSubmitting || !formik.isValid || !formik.touched
                }
              >
                <span className="indicator-label">Gönder</span>
                {formik.isSubmitting && (
                  <span className="indicator-progress">
                    Lütfen Bekleyin...{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateBanner;
